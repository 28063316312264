import React from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

export function Login() {
  const [signForm, setSignForm] = React.useState({
    velayat: "balkan",
    phone: "+993",
  });
  const [signError, setSignError] = React.useState({});
  const [loginForm, setLoginForm] = React.useState({});
  const [loginError, setLoginError] = React.useState(false);
  const [popupOpened, setPopupOpened] = React.useState(
    localStorage.getItem("loginModalOpened")
  );
  const [loginByPhoneNumberClicked, setLoginByPhoneNumberClicked] =
    React.useState(true);
  const navigate = useNavigate();

  const handleSignChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length < 4) return;
    setSignForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignSubmit = (e) => {
    e.preventDefault();

    const formData = {
      username: signForm.username,
      phone: signForm.phone,
      password: signForm.password,
      velayat: signForm.velayat,
    };

    var arr = {};

    if (!signForm.phone || signForm.phone.length !== 12) {
      arr.phone = "Telefon nomeri boş bolmaly däl";
    }

    const phoneRegex = /^\+993[6-7][1-5]\d{6}$/;
    if (!phoneRegex.test(signForm.phone)) {
      arr.phone = "Telefon nomeri dogry dal";
    }
    setSignError({ ...signError, ...arr });

    if (Object.keys(arr).length === 0) {
      axios
        .post("https://peydalan.com.tm/api/sign-up", formData)
        .then((response) => {
          // Handle the response, such as showing a success message
          setPopupOpened(true);
          localStorage.setItem("loginModalOpened", true);
          localStorage.setItem("username", response.data.username);
          const interval = setInterval(() => {
            axios
              .post("https://peydalan.com.tm/api/check-user", {
                username: localStorage.getItem("username"),
              })
              .then((response) => {
                localStorage.setItem("token", response.data.token);
                clearInterval(interval);
                setPopupOpened(false);
                localStorage.removeItem("loginModalOpened");
                navigate("/profile");
              })
              .catch((error) => {
                console.error(error);
              });
          }, 3000);
        })
        .catch((error) => {
          // Handle the error, such as showing an error message
          if (error.response.status === 403) {
            setSignError({ ...signError, ...error.response.data });
          }
        });
    }
  };

  if (localStorage.getItem("token")) {
    navigate("/profile");
  }
  React.useEffect(() => {
    if (localStorage.getItem("username")) {
      const interval_2 = setInterval(() => {
        axios
          .post("https://peydalan.com.tm/api/check-user", {
            username: localStorage.getItem("username"),
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            clearInterval(interval_2);
            setPopupOpened(false);
            localStorage.removeItem("loginModalOpened");
            navigate("/profile");
          })
          .catch((error) => {});
      }, 2000);
    }
  }, []);

  return (
    <div className="body">
      <div className="mainlogin">
        <Popup open={popupOpened} modal closeOnDocumentClick={false} nested>
          {(close) => (
            <div className="modal">
              <div className="header"> Maglumat </div>
              <div className="content">
                Hormatly <span className="bold">{signForm.username}</span> siziň
                <span className="bold">{signForm.phone}</span> nomeriňizden
                +99364197532 belgä 15 minudyň dowamynda boş SMS ugratmagyňyzy
                haýyş edýäris, siziň ugradan SMS-iňiz gelip gowuşandan soň
                maglumatlaryň dogrylygy deňeşdirilip awtomatik ýagdaýda ulanyşa
                giriziler.
              </div>
              {/*<div className="actions">
                <button
                  className="button"
                  onClick={() => {
                    close();
                    setPopupOpened(false);
                    localStorage.removeItem("loginModalOpened");
                  }}
                >
                  Yap
                </button>
              </div>*/}
            </div>
          )}
        </Popup>

        <div>
          <div className="signup">
            <form>
              <label className="label-login" htmlFor="chk" aria-hidden="true">
                Agza bolmak
              </label>
              <input
                className={
                  signError.phone
                    ? "input-login form-error password-error"
                    : "input-login"
                }
                type="text"
                name="phone"
                value={signForm.phone}
                placeholder={
                  signError.phone ? signError.phone : "Telefon nomeri"
                }
                required=""
                onChange={handleSignChange}
              />
              {signError.phone && (
                <span className="error-span">{signError.phone}</span>
              )}
              <button
                className="button-login button-login-by-id"
                onClick={handleSignSubmit}
              >
                Içeri gir
              </button>
            </form>
          </div>

          <div className="login">
            {/* <button
              className="button-login button-login-by-id bg-green"
              onClick={loginByOauth}
            >
              Gozle ID bilen gir
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export function Contact() {
  return (
    <div className="contact-card">
      Telefon Nomer: +99364197532 <br />
      Email: peydalan.com.tm@gmail.com <br />
      Imo: +99364197532 <br />
    </div>
  );
}

export function PasswordReset() {
  const [user, setUser] = React.useState();
  const [form, setForm] = React.useState({});
  const [verified, setVerified] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("+993");
  const [phoneError, setPhoneError] = React.useState(false);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const navigate = useNavigate();

  const checkVerified = () => {
    axios
      .get(
        "https://peydalan.com.tm/api/check-user-verified?phone_number=" +
          phoneNumber
      )
      .then((response) => {
        setVerified(true);
        setUser(response.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setPhoneError("Bu nomerde ulanyjy ýok");
          localStorage.clear("phone_number");
        } else {
          setPhoneError();
          localStorage.setItem("phone_number", phoneNumber);
          setPopupOpened(true);

          const interval = setInterval(() => {
            const phone_number = localStorage.getItem("phone_number");

            axios
              .get(
                "https://peydalan.com.tm/api/check-user-verified?phone_number=" +
                  phoneNumber
              )
              .then((response) => {
                clearInterval(interval);
                setPopupOpened(false);
                setVerified(true);
                setUser(response.data);
              })
              .catch((error) => {});
          }, 3000);
        }
      });
  };

  const changePassword = () => {
    if (form.password == form.password2) {
      var data = {
        password: form.password,
        phone_number: phoneNumber,
      };
      axios
        .post("https://peydalan.com.tm/api/reset-password", (data = data))
        .then((response) => {
          navigate("/settings");
        })
        .catch((error) => {});
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <div className="profile-page">
      <Popup open={popupOpened} modal closeOnDocumentClick={false} nested>
        {() => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">
              Hormatly ulanyjy, siziň nomeriňizden +99364197532 belgä boş SMS
              ugratmagyňyzy haýyş edýäris, siziň nomeriňiz dogry bolsa, açar
              sözüni çalşyp bilersiňiz.
            </div>
          </div>
        )}
      </Popup>
      <div className="profile-card">
        <div className="profile-ds-top"></div>
        {verified ? (
          <div className="profile-name profile-reset">
            <h3>{user.username}</h3>
            <br />
            <h5>Täze açar sözi:</h5>
            <input
              className={"input-reset"}
              type="password"
              name="password"
              placeholder="Açar sözi"
              value={form.password}
              onChange={handleFormChange}
              required=""
            />
            <h5>Täze açar sözi (täzeden):</h5>
            <input
              className={"input-reset"}
              type="password"
              name="password2"
              placeholder="Açar sözi (täzeden)"
              value={form.password2}
              onChange={handleFormChange}
              required=""
            />
            <button className="input-reset button" onClick={changePassword}>
              Açar sözüni çalyş
            </button>
          </div>
        ) : (
          <div className="profile-name profile-reset">
            <h5 className="profile-h5">Telefon Nomeri:</h5>
            <input
              className={
                phoneError
                  ? "input-reset form-error password-error"
                  : "input-reset"
              }
              type="text"
              name="phone"
              placeholder="Telefon nomeri"
              value={phoneNumber}
              onChange={handlePhoneChange}
              required=""
            />
            {phoneError && (
              <span className="reset-error-span">{phoneError}</span>
            )}
            <button className="input-reset button" onClick={checkVerified}>
              Açar sözüni çalyş
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export function OauthRedirect() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const code_verifier = localStorage.getItem("codeVerifier");

    const formData = {
      code: code,
      code_verifier: code_verifier,
    };

    var url = "https://peydalan.com.tm/api/oauth-login";

    axios
      .post(url, formData)
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
        navigate("/profile");
        // Handle the response, such as showing a success message
      })
      .catch((error) => {
        console.error(error);
      });

    // Add your logic here based on the query parameters
  }, [window.location.search]);

  return (
    <div className="body">
      <div className="mainlogin">Logging in...</div>
    </div>
  );
}
