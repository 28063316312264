import React from "react";
import { Link } from "react-router-dom";
import Badge from "./utils/Badge";
import axios from "axios";

export default function Navigation(props) {
  const sponsorsClick = (e) => {
    e.stopPropagation();
    props.menuBadgeSet("sponsor");
  };
  const chatClick = (e) => {
    props.menuBadgeSet("chat");
  };

  // React.useEffect(() => {
  //   if (props.notifications.sponsors > 0) {
  //     props.menuBadgeSet(props.notifications.sponsors);
  //   }
  // }, [props.notifications]);

  return (
    <div
      className="navigation"
      data-theme={props.theme}
      onClick={props.onClick}
    >
      <div className="container">
        <div className="inside">
          <div
            className="nav nav-tab menu"
            onClick={() => {
              props.menuBadgeSet(false);
            }}
          >
            {localStorage.getItem("token") ? (
              <Link
                to="profile"
                data-toggle="tab"
                onClick={() => {
                  props.setMenu("login");
                }}
              >
                <i
                  className={
                    props.navigation === "settings"
                      ? "material-icons active"
                      : "material-icons"
                  }
                >
                  info_outline{" "}
                </i>
              </Link>
            ) : (
              <Link
                to="settings"
                data-toggle="tab"
                onClick={() => {
                  props.setMenu("login");
                }}
              >
                <i
                  className={
                    props.navigation === "settings"
                      ? "material-icons active"
                      : "material-icons"
                  }
                >
                  info_outline{" "}
                </i>
              </Link>
            )}
            <Link
              to="games"
              data-toggle="tab"
              onClick={() => {
                props.setMenu("games");
              }}
            >
              <i className="material-icons calculate">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -960 960 960"
                >
                  <path
                    fill="#7a797c "
                    className={
                      props.navigation === "games"
                        ? "material-icons calculate active"
                        : "material-icons calculate"
                    }
                    d="M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z"
                  />
                </svg>
              </i>
            </Link>

            <Link
              to="/"
              data-toggle="tab"
              onClick={() => {
                props.setMenu("lesson");
              }}
            >
              <i
                className={
                  props.navigation === "lesson"
                    ? "material-icons active"
                    : "material-icons"
                }
              >
                g_translate
              </i>
            </Link>
            <Link
              to="chat/channel"
              data-toggle="tab"
              onClick={(e) => {
                chatClick(e);
              }}
            >
              {props.chatNotificationList.length > 0 && (
                <Badge count={props.chatNotificationList.length} />
              )}
              <i
                className={
                  props.navigation === "chat"
                    ? "material-icons active"
                    : "material-icons"
                }
              >
                chat_bubble_outline
              </i>
            </Link>
            <Link
              to="sponsors"
              onClick={(e) => {
                sponsorsClick(e);
                props.setMenu("all");
              }}
              data-toggle="tab"
              // onClick={() => {
              //   props.menuBadgeSet(props.notifications.sponsors);
              // }}
            >
              {props.notifications.sponsors > 0 && (
                <Badge count={props.notifications.sponsors} />
              )}
              <i
                className={
                  props.navigation === "sponsors"
                    ? "material-icons active"
                    : "material-icons"
                }
              >
                shop
              </i>
            </Link>
            <Link to="market" data-toggle="tab">
              <i
                className={
                  props.navigation === "market"
                    ? "material-icons active"
                    : "material-icons"
                }
              >
                shopping_cart
              </i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
