import { useNavigate } from "react-router-dom";
import React from "react";
import MessageCard from "../cards/MessageCard";
import axios from "axios";

export function ChooseLevel() {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("math", button_name);
    navigate("/math/level/1");
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Matematika</h4>
      <button
        className="test-button"
        onClick={() => {
          handleClick("+");
        }}
      >
        Goşmak (+)
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("-");
        }}
      >
        Aýyrmak (-)
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("+-");
        }}
      >
        Goşmak-Aýyrmak (+,-)
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("*");
        }}
      >
        Köpeltmek (*)
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("/");
        }}
      >
        Bölmek (/)
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("*/");
        }}
      >
        Köpeltmek-Bölmek (*,/)
      </button>
      <br />
      <h5>Düşündiriş teksti</h5>
      <p>
        Türgenleşik üçin islän matematiki amallaryňyzy saýlaň we türgenleşiň. Bu
        görnüşli türgenleşik matematiki mysallary çalt çözmekligiňize uly kömek
        eder we islendik amallary çalt çözmek endikleriňizi kämilleşdirer
      </p>
    </div>
  );
}

export function Level(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [isTyping, setIsTyping] = React.useState(false);

  const [ad, setAd] = React.useState();
  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const correctAnswer = React.useRef();
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer

  const counter = React.useRef(0);
  const statistics = React.useRef({ corrects: 0, errors: 0 });

  const input = React.useRef();
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const welcomeMessage = React.useRef("");

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };
  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };
  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (message) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: message,
        me: false,
        info: true,
      },
    ]);
  };

  const sendMessage = (e) => {
    if (message && message !== "\n") {
      counter.current = counter.current + 1;
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (message == correctAnswer.current) {
        setIsTyping(true);
        statistics.current = {
          ...statistics.current,
          corrects: statistics.current.corrects + 1,
        };
        setTimeout(() => {
          sendInfo("👍 Dogry.");
          playCorrectSound();
        }, 1000);

        setTimeout(() => {
          sendQuestion();
        }, 2000); // send another question
      } else {
        setTimeout(() => {
          statistics.current = {
            ...statistics.current,
            errors: statistics.current.errors + 1,
          };
          sendError(correctAnswer.current);
          playWrongSound();
        }, 1000);
        setTimeout(() => {
          sendQuestion();
        }, 2000);
      }
      if (counter.current == 20) {
        counter.current = 0;
        sendInfo(
          "20 mysaldan \n Dogrysy: " +
            statistics.current.corrects +
            " \n Ýalňyşy: " +
            statistics.current.errors
        );
        setTimeout(() => {}, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const getRandomNumber = (minNumber, maxNumber) => {
    const randomNumber =
      Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
    return randomNumber;
  };

  const sendQuestion = () => {
    // check if there are any questions left
    var operation = localStorage.getItem("math");
    if (operation == "+-") {
      operation = ["+", "-"][Math.floor(Math.random() * 2)];
    } else if (operation == "*/") {
      operation = ["*", "/"][Math.floor(Math.random() * 2)];
    }
    var number1 = getRandomNumber(props.minNumber, props.maxNumber);
    var number2 = getRandomNumber(props.minNumber, props.maxNumber);
    if ((operation == "-") | (operation == "/")) {
      while ((number1 < number2) | (number1 === 0) | (number2 === 0)) {
        var number1 = getRandomNumber(0, props.maxNumber);
        var number2 = getRandomNumber(0, props.maxNumber);
      }
    }
    if (operation == "/") {
      while (
        (number1 % number2 !== 0) |
        (number1 === 0) |
        (number2 === 1) |
        (number1 === number2)
      ) {
        var number1 = getRandomNumber(1, props.maxNumber);
        var number2 = getRandomNumber(1, props.maxNumber);
      }
    }
    if (operation == "+") {
      while (
        (number1 + number2 > props.maxNumber) |
        (number1 + number2 < props.minNumber) |
        (number1 === 0) |
        (number2 === 0)
      ) {
        var number1 = getRandomNumber(0, props.maxNumber);
        var number2 = getRandomNumber(0, props.maxNumber);
      }
    }
    if (operation == "*") {
      while (
        (number1 * number2 > props.maxNumber) |
        (number1 === 0) |
        (number2 === 0) |
        (number1 === 1) |
        (number2 === 1)
      ) {
        var number1 = getRandomNumber(1, props.maxNumber);
        var number2 = getRandomNumber(1, props.maxNumber);
      }
    }
    setIsTyping(false);
    // get the question at that index
    setDialog((dialogs) => [
      ...dialogs,
      {
        message:
          number1.toString() + " " + operation + " " + number2.toString(),
        me: false,
      },
    ]);
    if (operation == "+") {
      correctAnswer.current = number1 + number2; // add the question to the dialog
    } else if (operation == "-") {
      correctAnswer.current = number1 - number2; // add the question to the dialog
    } else if (operation == "*") {
      correctAnswer.current = number1 * number2; // add the question to the dialog
    } else if (operation == "/") {
      correctAnswer.current = number1 / number2; // add the question to the dialog
    }
  };

  React.useEffect(() => {
    var operation_verbose;
    var operation = localStorage.getItem("math");
    if (operation == "-") {
      operation_verbose = "aýyrmak";
    } else if (operation == "+") {
      operation_verbose = "goşmak";
    } else if (operation == "*") {
      operation_verbose = "köpeltmek";
    } else if (operation == "/") {
      operation_verbose = "bölmek";
    } else if (operation == "*/") {
      operation_verbose = "köpeltmek we bölmek";
    } else if (operation == "+-") {
      operation_verbose = "goşmak we aýyrmak";
    }

    if (props.level == 1) {
      welcomeMessage.current =
        "Salam! Matematika öwrenmegiň 1-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 0-dan 25-e çenli sanlary " +
        operation_verbose +
        " arkaly türgenleşik geçeris. Üstünlik!";
    } else if (props.level == 2) {
      welcomeMessage.current =
        "Salam! Matematika öwrenmegiň 2-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 25-den 50-ä çenli sanlary " +
        operation_verbose +
        " arkaly türgenleşik geçeris. Üstünlik!";
    } else if (props.level == 3) {
      welcomeMessage.current =
        "Salam! Matematika öwrenmegiň 3-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 50-den 100-e çenli sanlary " +
        operation_verbose +
        " arkaly türgenleşik geçeris. Üstünlik!";
    } else if (props.level == 4) {
      welcomeMessage.current =
        "Salam! Matematika öwrenmegiň 4-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 100-den 250-e çenli sanlary " +
        operation_verbose +
        " arkaly türgenleşik geçeris. Üstünlik!";
    } else if (props.level == 5) {
      welcomeMessage.current =
        "Salam! Matematika öwrenmegiň 5-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 250-den 500-e çenli sanlary " +
        operation_verbose +
        " arkaly türgenleşik geçeris. Üstünlik!";
    } else if (props.level == 6) {
      welcomeMessage.current =
        "Salam! Matematika öwrenmegiň 6-njy ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 500-den 1000-e çenli sanlary " +
        operation_verbose +
        " arkaly türgenleşik geçeris. Üstünlik!";
    }

    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (ad) {
      setTimeout(() => {
        setDialog((dialog) => [
          {
            message: ad.image,
            me: false,
            ad: true,
            view: ad.view,
            id: ad.id,
          },
          ...dialog.slice(1),
        ]);
      }, 500);
    }
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                ad={item.ad}
                view={item.view}
                setInputFocus={setInputFocus}
                sponsorId={item.id}
                message={item.message}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage();
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
