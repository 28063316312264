import React from "react";

export default function GeographyTestCard(props) {
  const [selectedButton, setSelectedButton] = React.useState(0);
  const audioRef = React.useRef();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  const selectAnswer = (id) => {
    props.selectedSet(id);
    setSelectedButton(id);
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="message">
      <div className="text-main">
        <div className="text-group">
          {props.mode === "1" ? (
            <div className={classes}>
              <i
                onClick={playAudio}
                className={"material-icons geography-audio-button"}
              >
                volume_up
              </i>
              <h6>{props.question}</h6>
              <audio src={props.audio} autoPlay ref={audioRef} />
              <div className="geography-answers">
                <img
                  className={
                    selectedButton && props.true === props.image1
                      ? "answer-button selected correct"
                      : selectedButton === props.image1 &&
                        props.true !== props.image1
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.image1);
                    }
                  }}
                  disabled={selectedButton && true}
                  src={props.image1}
                />

                <img
                  className={
                    selectedButton && props.true === props.image2
                      ? "answer-button selected correct"
                      : selectedButton === props.image2 &&
                        props.true !== props.image2
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.image2);
                    }
                  }}
                  src={props.image2}
                />

                <img
                  className={
                    selectedButton && props.true === props.image3
                      ? "answer-button selected correct"
                      : selectedButton === props.image3 &&
                        props.true !== props.image3
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.image3);
                    }
                  }}
                  disabled={selectedButton && true}
                  src={props.image3}
                />
                <img
                  className={
                    selectedButton && props.true === props.image4
                      ? "answer-button selected correct"
                      : selectedButton === props.image4 &&
                        props.true !== props.image4
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.image4);
                    }
                  }}
                  disabled={selectedButton && true}
                  src={props.image4}
                />
              </div>
            </div>
          ) : (
            <div className="text geography-message-2">
              <img src={props.question} />
              <div className="geography-answers-2">
                <button
                  className={
                    selectedButton && props.true === props.answer1.name
                      ? "answer-button selected correct-2"
                      : selectedButton === props.answer1.name &&
                        props.true !== props.answer1.name
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer1.name);
                    }
                  }}
                >
                  {props.answer1.name} ({props.answer1.name_turkmen})
                </button>
                <button
                  className={
                    selectedButton && props.true === props.answer2.name
                      ? "answer-button selected correct-2"
                      : selectedButton === props.answer2.name &&
                        props.true !== props.answer2.name
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer2.name);
                    }
                  }}
                >
                  {props.answer2.name} ({props.answer2.name_turkmen})
                </button>
                <button
                  className={
                    selectedButton && props.true === props.answer3.name
                      ? "answer-button selected correct-2"
                      : selectedButton === props.answer3.name &&
                        props.true !== props.answer3.name
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer3.name);
                    }
                  }}
                >
                  {props.answer3.name} ({props.answer3.name_turkmen})
                </button>
                <button
                  className={
                    selectedButton && props.true === props.answer4.name
                      ? "answer-button selected correct-2"
                      : selectedButton === props.answer4.name &&
                        props.true !== props.answer4.name
                      ? "answer-button selected error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer4.name);
                    }
                  }}
                >
                  {props.answer4.name} ({props.answer4.name_turkmen})
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
