import React from "react";
import axios from "axios";
import AlphabetMessageCard from "../cards/AlphabetMessageCard";
import AlphabetTestCard from "../cards/AlphabetTestCard";
import Popup from "react-animated-popup";
import { useNavigate } from "react-router-dom";

export function AlphabetLevel(props) {
  const [ad, setAd] = React.useState([]);
  const [dialog, setDialog] = React.useState([{}]);
  const alphabet = React.useRef();
  const alphabet_backup = React.useRef();
  const [isTyping, setIsTyping] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [correctAnswer, setCorrectAnswer] = React.useState();
  const messagesEndRef = React.useRef(null);
  const welcomeMessage = React.useRef("");
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const input = React.useRef();
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const [gif_source, setGifSource] = React.useState(
    "https://peydalan.com.tm/api/media/system_sounds/emoji-like.gif"
  );
  const [visible, setVisible] = React.useState(false);
  const statistics = React.useRef({ corrects: 0, errors: 0 });

  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };

  const sendStatistics = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: text,
        me: false,
        info: true,
      },
    ]);
  };

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  React.useEffect(() => {
    const url =
      "https://peydalan.com.tm/api/alphabet/letters?language=" +
      localStorage.getItem("alphabet");
    axios
      .get(url)
      .then((response) => {
        // set the array state to the response data
        alphabet.current = response.data;
        alphabet_backup.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sendMessage = () => {
    const currentMessage = message;
    if (message.toUpperCase().replace("\n", "") !== correctAnswer.letter) {
      setGifSource(
        "https://peydalan.com.tm/api/media/system_sounds/emoji-no.gif"
      );
      setMessage("");
      playWrongSound();
      statistics.current = {
        ...statistics.current,
        errors: statistics.current.errors + 1,
      };
    } else {
      setGifSource(
        "https://peydalan.com.tm/api/media/system_sounds/emoji-like.gif"
      );
      setTimeout(() => {
        sendQuestion();
      }, 3000);
      setDialog((dialogs) => [
        ...dialogs,
        { message: currentMessage, me: true },
      ]);
      setMessage("");
      playCorrectSound();
      statistics.current = {
        ...statistics.current,
        corrects: statistics.current.corrects + 1,
      };
    }
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2000);

    deleteItem(correctAnswer.letter);
    input.current.focus();
    if (alphabet.current.length == 0) {
      alphabet.current = alphabet_backup.current;

      sendStatistics(
        alphabet_backup.current.length +
          " soragdan \n Dogrysy: " +
          statistics.current.corrects +
          " \n Ýalňyşy: " +
          statistics.current.errors
      );
      statistics.current = { corrects: 0, errors: 0 };
    }
  };

  const getRandomObject = (array) => {
    const randomObject = array[Math.floor(Math.random() * array.length)];
    return randomObject;
  };

  const sendQuestion = () => {
    if (alphabet) {
      const question = getRandomObject(alphabet.current);
      setCorrectAnswer(question);
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          question: question,
          me: false,
        },
      ]);
    }
  };

  const deleteItem = (letter) => {
    alphabet.current = alphabet.current.filter(
      (item) => item.letter !== letter
    );
  };

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    welcomeMessage.current = "Salam!";
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <Popup
        visible={visible}
        onClose={() => setVisible(false)}
        style={{ backgroundColor: "transparent", position: "fixed" }}
      >
        <img src={gif_source} style={{ width: "100%" }}></img>
      </Popup>
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <AlphabetMessageCard
                key={index}
                ad={item.ad}
                view={item.view}
                sponsorId={item.id}
                message={item.message}
                audio={item.audio}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
                question={item.question}
                scrollToBottom={scrollToBottom}
              />
            ))}
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef}></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AlphabetLevel2(props) {
  const [ad, setAd] = React.useState([]);
  const [dialog, setDialog] = React.useState([{}]);
  const [selected, setSelected] = React.useState();
  const letters = React.useRef();
  const letters_backup = React.useRef();
  const [isTyping, setIsTyping] = React.useState(false);
  const [correctAnswer, setCorrectAnswer] = React.useState();
  const messagesEndRef = React.useRef(null);
  const welcomeMessage = React.useRef("");
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const [gif_source, setGifSource] = React.useState(
    "https://peydalan.com.tm/api/media/system_sounds/emoji-like.gif"
  );
  const [visible, setVisible] = React.useState(false);
  const statistics = React.useRef({ corrects: 0, errors: 0 });
  const timeout = React.useRef(0);

  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };

  const sendStatistics = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: text,
        me: false,
        info: true,
      },
    ]);
  };

  React.useEffect(() => {
    const url =
      "https://peydalan.com.tm/api/alphabet/letters?language=" +
      localStorage.getItem("alphabet");
    axios
      .get(url)
      .then((response) => {
        // set the array state to the response data
        letters.current = response.data;
        letters_backup.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const selectedSet = (text) => {
    setSelected(text);
  };

  function getRandomAnswers(answer) {
    // Create a copy of the state array
    let copy = [...letters_backup.current];
    // Remove the given element from the copy
    let index = copy.indexOf(answer);
    if (index > -1) {
      copy.splice(index, 1);
    }
    // Shuffle the copy array
    for (let i = copy.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [copy[i], copy[j]] = [copy[j], copy[i]];
    }
    // Return the first two elements of the copy array
    return copy.slice(0, 3);
  }

  const getRandomObject = (array) => {
    const randomObject = array[Math.floor(Math.random() * array.length)];
    return randomObject;
  };

  const sendQuestion = () => {
    if (letters) {
      const question = getRandomObject(letters.current);
      var falseAnswers = getRandomAnswers(question);
      falseAnswers = [...falseAnswers, question];

      const answers = shuffleArray(falseAnswers);
      setCorrectAnswer(question);
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          question: question,
          answer1: answers[0],
          answer2: answers[1],
          answer3: answers[2],
          answer4: answers[3],
          me: false,
        },
      ]);
    }
  };

  const deleteItem = (letter) => {
    letters.current = letters.current.filter((item) => item.letter !== letter);
  };

  React.useEffect(() => {
    if (!selected) {
      return;
    }

    var c_answer = correctAnswer.letter;
    // Then use the setState method to update the state with the new array
    if (selected === c_answer) {
      playCorrectSound();

      statistics.current = {
        ...statistics.current,
        corrects: statistics.current.corrects + 1,
      };
      setGifSource(
        "https://peydalan.com.tm/api/media/system_sounds/emoji-like.gif"
      );
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2000);
      setTimeout(() => {
        sendQuestion();
      }, 2500);
    } else {
      playWrongSound();

      statistics.current = {
        ...statistics.current,
        errors: statistics.current.errors + 1,
      };
      setGifSource(
        "https://peydalan.com.tm/api/media/system_sounds/emoji-no.gif"
      );
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2000);
      setTimeout(() => {
        sendQuestion();
      }, 2500);
    }
    deleteItem(correctAnswer.letter);
    setSelected(null);
    if (letters.current.length == 0) {
      letters.current = letters_backup.current;

      sendStatistics(
        letters_backup.current.length +
          " soragdan \n Dogrysy: " +
          statistics.current.corrects +
          " \n Ýalňyşy: " +
          statistics.current.errors
      );
      statistics.current = { corrects: 0, errors: 0 };
    }
  }, [selected]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    welcomeMessage.current =
      "Salam! Dünýä ýurtlarynyň atlaryny, baýdaklaryny we ýerleşýän ýerlerini öwrenmek türgenleşiginiň 1-nji ädimine hoş geldiňiz. Bu sapakda ulylygy boýunça 1-20 aralykdaky ýurtlar görkeziler. Ulanylyş usuly size bir ýurduň ady geler we dört ýurduň baýdagynyň suraty geler, siz şol baçdaklardan birini saýlaýarsyňyz, soňra netije görkezilende şol ýurt barada maglumat görkeziler we ýene täzeden bu ýagdaý gaýtalanar.";
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <Popup
        visible={visible}
        onClose={() => setVisible(false)}
        style={{ backgroundColor: "transparent", position: "fixed" }}
      >
        <img src={gif_source} style={{ width: "100%" }}></img>
      </Popup>
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tmpi/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.question ? (
                <AlphabetTestCard
                  key={index}
                  question={item.question}
                  answer1={item.answer1}
                  answer2={item.answer2}
                  answer3={item.answer3}
                  answer4={item.answer4}
                  selectedSet={selectedSet}
                  scrollToBottom={scrollToBottom}
                  level={props.level}
                />
              ) : (
                <AlphabetMessageCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                disabled
                className="form-control"
                placeholder="Doňdurylan..."
                rows="0"
              ></textarea>
              <button type="submit" className="btn send">
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChooseAlphabetLanguage() {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("alphabet", button_name);
    navigate("/alphabet/2");
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Harplyk</h4>
      <button
        className="test-button"
        onClick={() => {
          handleClick("tk");
        }}
      >
        Türkmen Harplyk
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("ru");
        }}
      >
        Rus Harplyk
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("en");
        }}
      >
        Iňlis Harplyk
      </button>
      <br />
      <h5>Düşündiriş teksti</h5>
      <p></p>
    </div>
  );
}
