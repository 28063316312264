import React from "react";
import axios from "axios";
import Card from "./cards/FeedbackCard";

export function Feedbacks() {
  const [feedbacks, setFeedbacks] = React.useState([]);

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/market/feedbacks/get", {
        headers: headers,
      })
      .then((response) => {
        // set the array state to the response data

        setFeedbacks(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const deleteFeedback = (feedbackId) => {
    const updatedFeedbacks = feedbacks.filter(
      (feedback) => feedback.id !== feedbackId
    );
    setFeedbacks(updatedFeedbacks);
  };

  return (
    <div className="text-frame">
      <div>
        <h2>Şykaýatlar</h2>
      </div>
      {feedbacks.map((item) => (
        <Card key={item.id} item={item} deleteFeedback={deleteFeedback} />
      ))}
    </div>
  );
}
