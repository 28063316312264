import React from "react";
import { Link } from "react-router-dom";

export default function ProgressSidebar(props) {
  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
    // setTimeout(() => {}, 1000);
  };

  React.useEffect(() => {
    props.navigationset("settings");
  }, []);

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Derejeleri gör</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "english");
            }}
            to="/progress/english"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "english" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Iňlis dili</h5>
              <p>Iňlis dilinde ýetilen dereje</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "russian");
            }}
            to="/progress/russian"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "russian" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Rus dili</h5>
              <p>Rus dilinde ýetilen dereje</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chinese");
            }}
            to="/progress/chinese"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chinese" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Hytaý dili</h5>
              <p>Hytaý dilinde ýetilen dereje</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "top-users");
            }}
            to="/progress/top-users"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "top-users" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Aktiw ulanyjylar</h5>
              <p>Iň köp söz öwrenen ulanyjylary görüň</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
