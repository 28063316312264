import React, { useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate, useParams } from "react-router-dom";
import { CircleLoader } from "react-spinners";

export function ServiceAdminCard(props) {
  const [opened, setOpened] = React.useState(false);
  const [current, setCurrent] = React.useState(1);
  const [favorite, setFavorite] = React.useState(props.item.liked);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 3
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  const editButton = () => {
    navigate("/market/mine/service/edit/" + props.item.id);
  };

  const deleteButton = () => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    const data = { service_id: props.item.id };
    axios
      .delete("https://peydalan.com.tm/api/service/admin", {
        headers: headers,
        data: data,
      })
      .then((response) => {
        props.refresh();
      });
  };

  return (
    <div className="text-content-frame">
      {!opened && props.item.image_1 && <img src={props.item.image_1} />}
      <div style={{ display: opened ? "block" : "none" }}>
        <div className="slideshow-container">
          {current == 1 && (
            <div>
              <div className="numbertext">1 / 3</div>
              <img src={props.item.image_1} className="slide-img" />
            </div>
          )}
          {current == 2 && (
            <div>
              <div className="numbertext">2 / 3</div>
              <img
                src={props.item.image_2 ? props.item.image_2 : props.item.image}
                className="img"
              />
            </div>
          )}
          {current == 3 && (
            <div>
              <div className="numbertext">3 / 3</div>
              <img
                src={props.item.image_3 ? props.item.image_3 : props.item.image}
                className="img"
              />
            </div>
          )}
          <a
            className="prev"
            onClick={() => {
              change(false);
            }}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => {
              change(true);
            }}
          >
            &#10095;
          </a>
        </div>
      </div>
      <table className="product-table">
        <tr className="product-row">
          <td className="product-title">Kategoriýasy:</td>
          <td className="product-info">
            {props.item.market.category_service.name}
          </td>
        </tr>
        <tr className="product-row">
          <td className="product-title">Bahasy:{"  "}</td>
          <td className="product-info">{props.item.price}</td>
        </tr>
      </table>
      {opened && <div className="product-about">{props.item.about}</div>}
      {opened && (
        <div className="product-market-info">
          {props.item.market.name}
          {"  "}
          {props.item.market.phone_number}
          {"  "}({props.item.market.code})
        </div>
      )}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
      <button className="text-button" onClick={editButton}>
        Üýtget
      </button>
      <button className="text-button" onClick={deleteButton}>
        Poz
      </button>
    </div>
  );
}

export function ServiceAddForm(props) {
  const [image1, setImage1] = React.useState();
  const [image2, setImage2] = React.useState();
  const [image3, setImage3] = React.useState();
  const [preview1, setPreview1] = React.useState();
  const [preview2, setPreview2] = React.useState();
  const [preview3, setPreview3] = React.useState();
  const [price, setPrice] = React.useState();
  const [about, setAbout] = React.useState();
  const [errors, setErrors] = React.useState();
  const [message, setMessage] = React.useState("Bir zatlar yalnysh gitdi");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [popupOpened, setPopupOpened] = React.useState(false);

  const onFileChange1 = (event) => {
    // Update the state
    setImage1(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview1(objectUrl);
  };

  const onFileChange2 = (event) => {
    // Update the state
    setImage2(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview2(objectUrl);
  };

  const onFileChange3 = (event) => {
    // Update the state
    setImage3(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview3(objectUrl);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleAboutChange = (event) => {
    setAbout(event.target.value);
  };

  const addService = () => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("image_1", image1);
    formData.append("image_2", image2);
    formData.append("image_3", image3);
    formData.append("price", price);
    formData.append("about", about);

    setLoading(true);

    axios
      .post("https://peydalan.com.tm/api/service/admin", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        navigate("/market/mine/services");
      })
      .catch((error) => {
        if (error.response.status == 403) {
          setLoading(false);
          setErrors(error.response.data);
          if (error.response.data.market) {
            setMessage("Dine 1 hyzmat goshmaga rugsat berlen");
            setPopupOpened(true);
          }
        } else {
          setLoading(false);
          setMessage("Bir zatlar yalnysh gitdi");
          setPopupOpened(true);
        }
      });
  };

  return (
    <div>
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">{message}</div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                OK
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      {loading ? (
        <CircleLoader className="loader" color="#33A2F4" size={90} />
      ) : (
        <div className="admin-form">
          <div
            className={
              "admin-form-element " +
              (errors && errors.image_1 && "admin-form-element-error")
            }
          >
            {preview1 && <img src={preview1} />}
            <input type="file" onChange={onFileChange1} />
            <span className="admin-form-error-span">
              {errors && errors.image_1}
            </span>
          </div>
          <div
            className={
              "admin-form-element " +
              (errors && errors.image_2 && "admin-form-element-error")
            }
          >
            {preview2 && <img src={preview2} />}
            <input type="file" onChange={onFileChange2} />
            <span className="admin-form-error-span">
              {errors && errors.image_2}
            </span>
          </div>
          <div
            className={
              "admin-form-element " +
              (errors && errors.image_3 && "admin-form-element-error")
            }
          >
            {preview3 && <img src={preview3} />}
            <input type="file" onChange={onFileChange3} />
            <span className="admin-form-error-span">
              {errors && errors.image_3}
            </span>
          </div>
          <div className="admin-form-div">
            <span>Bahasy:</span>
            <input type={"number"} onChange={handlePriceChange} />
          </div>
          <div className="admin-form-div">
            <span>Hakynda:</span>
            <textarea onChange={handleAboutChange} rows={10}>
              {" "}
            </textarea>
          </div>
          <button className="admin-form-button" onClick={addService}>
            Add
          </button>
        </div>
      )}
    </div>
  );
}

export function ServiceEditForm(props) {
  const { id } = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [preview1, setPreview1] = React.useState();
  const [preview2, setPreview2] = React.useState();
  const [preview3, setPreview3] = React.useState();

  React.useEffect(() => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    axios
      .get("https://peydalan.com.tm/api/service/check?id=" + id, {
        headers: headers,
      })
      .then((response) => {
        setData(response.data);
        setPreview1(response.data.image_1);
        setPreview2(response.data.image_2);
        setPreview3(response.data.image_3);
      })
      .catch((error) => {
        navigate("/market/mine/services");
      });
  }, []);

  const onFileChange1 = (event) => {
    // Update the state
    setData({ ...data, ["image_1"]: event.target.files[0] });

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview1(objectUrl);
  };

  const onFileChange2 = (event) => {
    // Update the state
    setData({ ...data, ["image_2"]: event.target.files[0] });

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview2(objectUrl);
  };

  const onFileChange3 = (event) => {
    // Update the state
    setData({ ...data, ["image_3"]: event.target.files[0] });

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview2(objectUrl);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const saveService = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("image_1", data.image_1);
    formData.append("image_2", data.image_2);
    formData.append("price", data.price);
    formData.append("about", data.about);

    axios
      .patch("https://peydalan.com.tm/api/service/admin", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        navigate("/market/mine/services");
      });
  };

  return (
    <div className="admin-form">
      {data && (
        <div>
          <div className="admin-form-element">
            {preview1 && <img src={preview1} />}
            <input type="file" onChange={onFileChange1} />
          </div>
          <div className="admin-form-element">
            {preview2 && <img src={preview2} />}
            <input type="file" onChange={onFileChange2} />
          </div>
          <div className="admin-form-element">
            {preview3 && <img src={preview3} />}
            <input type="file" onChange={onFileChange3} />
          </div>
          <div className="admin-form-div">
            <span>Bahasy:</span>
            <br />
            <input
              type={"number"}
              name="price"
              onChange={handleFormChange}
              value={data.price}
            />
          </div>
          <div className="admin-form-div">
            <span>Hakynda:</span>
            <br />
            <textarea
              name="about"
              onChange={handleFormChange}
              value={data.about}
              rows={10}
            ></textarea>
          </div>
          <button className="admin-form-button" onClick={saveService}>
            Save
          </button>
        </div>
      )}
    </div>
  );
}
