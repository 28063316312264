import React from "react";

export function AboutSponsors() {
  return (
    <div className="chat" id="chat1">
      <div className="container container-main about-container">
        <h4>Hemaýatçylar barada</h4>
        <p>
          Arakesme sahypamyza gireniňdäki HEMAÝATÇYLAR hyzmaty web saýtymyzyň
          sagdyn saklanmagynyň esasy diregleriniň biri bolup durýar. Bu
          hyzmatymyz iki bölümden ybarat bolup, bu bölümlerde biziň işimizden
          haýyr tapyp, bize ýakyndan hemaýat edýän ildeşlerimiziň hemde mahabat
          hyzmatlarynyň sanawy görkeziler.
        </p>
        <h4>ULY HEMAÝATÇYLAR</h4>
        <p>
          Ikinji bölümimiz bolan ULY HEMAÝATÇYLAR sahypamyzda ildeşlerimiziň
          daşary ýurt dilleriniň başlangyç sapaklaryny we goşmaça okuw
          türgenleşiklerini öwrenmekde gerek maglumatlaryny tölegsiz ulanmaklygy
          üçin hemaýat edýän Hemaýatçylarymyza ýazylmaga diňe 10 ýer bellenilen
          we her ýere üç mahabat suraty bolan we maglumat tekstini + link
          ýerleşdirip berýäris. Sözlük öwrenmek üçin gelen ildeşlermizi biziň
          robot mugallymymyz ilki uly hemaýatçylar bilen tanyşdyryp soňra okuw
          sapagyna girişer.
        </p>
        <h4>TELEKEÇILER</h4>
        <p>
          Bizde mahabat hyzmatlaryny ulanyp biziň işlerimiziň hilini ösdürmek
          üçin goldaw berýän telekeçilerimize jogap edip öz işleriniň hem ösmegi
          üçin web saýtymyza bellenilen wagtly mahabat hyzmatlaryny ýerleşdirip
          berýäris. Täze goýulan mahabat hyzmatlarymyz ulanyjylaryň ünsüni özüne
          çeker ýaly täze goşulanlar belligi çykyp ulanyjylar mahabatyňyz bilen
          tanyş bolar ýaly mümkinçilikler gurnalan.
        </p>
      </div>
    </div>
  );
}

export function AboutMath() {
  return (
    <div className="chat" id="chat1">
      <div className="container container-main about-container">
        <h4>Arakesme barada</h4>
        <p>
          <i className="material-icons about-icons">notifications_none</i>{" "}
          Matematika
        </p>
        <p>Matematika hakynda</p>
      </div>
    </div>
  );
}

export function AboutLesson() {
  return (
    <div className="chat" id="chat1">
      <div className="container container-main about-container">
        <h4>Web saýtymyz arkaly sözlük öwrediş usulymyz:</h4>
        <ol>
          <li>
            Daşary ýurt dillerini öwrenmek isleýän ildeşlerimiziň özbaşdak
            sözlük öwrenip bilmekleri üçin amatly bolar ýaly web saýty gurnaldy.
          </li>
          <li>
            Öwrenijileriň sözlük öwrenmegi gyzykly bolar ýaly çat boty gurnalan,
            ýagny bot bilen ýazyşmak arkaly öwredilýär.
          </li>
          <li>
            Ulanyja her bir öwrenen sözüni gepleşikde ulanylyşyny görer ýaly
            maksatnama göz öňüne tutulan. Bu usul öwrenijä her bir öwrenen
            sözüniň manysyna doly düşünip geçmegine kömek berýär.
          </li>
          <li>
            Her sapakda ulanyja öwrenmäge ortaça 20-30 söz berilýär we näçe
            wagtda öwrenýänligine wagt kesgitlenilmeýär.
          </li>
          <li>
            Ulanyja artykmaç ýadawlyk bolmazlygy üçin öwredilýän tekstiniň
            içindäki ulanylan sözlerde öň geçilmedik sözler bolmazlygy gurnalan,
            tekstiň içindäki sözleriň ählisi şu wagtky öwrenýän sapagy we ondan
            öňki geçen sapaklaryndan alynan sözler esasynda düzülen.
          </li>
          <li>
            Sapagymyzyň birinji ädiminde Robot tarapyndan öwredilmek üçin
            ugradylýan sözlere ulanyjy doly üns bermegi üçin şol sözi göçürmeli
            edilen, bu usulda ulanyjy şol sözüň dogry ýazylyşyny hem ýat tutýar,
            bu usulyň ýene bir peýdasy ulanyjynyň ellerini hem çalt ýazmaklyga
            türgenleşdirýär.
          </li>
          <li>
            Sapagymyzyň ikinji we üçünji ädimi birinji ädimdäki öwrenilen
            sözleriniň testi bolup, ulanyjynyň eşidip düşünmek we sözleri
            ýatlamak ukybyny ösdürmek göz öňüne tutulan.
          </li>
          <li>
            Sapagymyzyň soňky ädimleri öwrenilen sözleri sözlemlerde getirip
            gepleşige taýýarlanmaga kömek edip biler.
          </li>
          <li>
            peydalan.com.tm web saýtymyzda daşary ýurt dilleriniň sözlüklerini
            öwrenmegiň başgada amatly usullary öwrenmeklik gözlegde saklanylýar.
          </li>
        </ol>
        <h4>Sözlük öwrenmäge girişmek</h4>
        <p>
          Sözlük öwrenmek üçin{" "}
          <i className="material-icons about-icons">chat_bubble_outline</i>{" "}
          şekilli sahypamyzdan dil saýla bölüminden bilýän diliňizi hem-de
          öwrenjek diliňizi we sapagyňyzy saýlasaňyz mazmuny düwmäniň aşagynda
          bilýän diliňizden, öwrenjek diliňize degişli giriş teksti ýazylandyr,
          bu tekst täze başlaýan okyjylar üçin zerur bolup biler, soňra mazmuny
          düwmä basyp öwrediljek sapagyňyzyň mazmuny bilen tanyşarsyňyz, siz bu
          sahypadan öz öwrenmeli sözleriňiziň sany näçeräkdigini we haýsy
          sözlerdigini hem-de öwrenmeli tekstiňiz bilen tanyşarsyňyz. Soňra bu
          sahypanyň aşagynda sapaga başla düwme bardyr, siz bu düwma basyp
          sözlük öwrenmegiň birinji ädimine geçersiňiz.
        </p>
        <h4>Maslahatlarymyz:</h4>
        <p>
          Hormatly ata-eneler we mugallymlar. Biziň bu web saýtymyzda
          ene-atalar, mugallymlar we halypalar hem öz okyjylaryna daşary ýurt
          sözlerini öwretmekde özbaşdak ideg edip bilerler. Mysal üçin:
          Öwrenijisiniň haýsy sapakda we haýsy ädime ýetendigini yzarlamaly, we
          näçinji sapagyň näçinji ädimini özleşdirendigi barada hasabat
          berdirmeli, öwrenijiňiziň ýetişigini şol sapagyň şol ädiminde ýanynda
          oturyp test almaly we netije çykarmaly.
        </p>
        <p>
          Saglyk adamzadyň durmuşynda iň wajyp zat bolip durỳar. Saglyk
          meselelerine hökmany uly üns berilmelidir. Haýsydyr bir zady
          öwrenmegiň herhili ýollary bar. Mysal üçin: tejribäniň üsti öwrenmek,
          kitap arkaly öwrenmek, diňläp öwrenmek we häzirki zaman iň öňdebaryjy
          usullaryň biri bolan kompýuter tehnologiýalarynyň üsti bilen öwrenmek
          we ş.m. Biziň bu usulymyz hem kompýuter tehnologiyalary arkaly sözlük
          öwretmek usuly bolup durýar. Bu usulda öwrenme tizligi örän ýokary
          derejede bolup durýar, ýöne bu tehnologiýalary dogry ulanylmasa adam
          saglygyna zyýan ýetirip bilýär. Mysal üçin: kompyuteriň öňünde aşa köp
          oturmak, köp oturylanda zyýanly ýagtylykdan goraýan äýnekleri
          dakynmazlyk we oturgyçda egri oturmak ýaly zyýanly endikler saglyga
          zyýan berip biler. Bu endikleri ýeňip geçmek üçin özüňize wagtly
          tertip düzmeli, dogry iýmitlenmeli. Bedenterbiýe, iş, okuw we internet
          okuwlaryňyzyň wagtyny netijeli we oýlanyşykly edip düzmeli.
        </p>
      </div>
    </div>
  );
}

export function AboutSettings() {
  return (
    <div className="chat" id="chat1">
      <div className="container container-main about-container">
        <h4>Sazlamalar barada</h4>
        <p>
          <i className="material-icons about-icons">settings</i> sazlamalar
          düwmesiniň Agza bolmak sahypasynda bize agza bolmak mümkinçiligi
          gurnalan, bize agza bolmak bilen siz öziňize birnaçe mümkinçilikleri
          gazanyp bilersiňiz. Mysal üçin bize agza bolmankaňyz{" "}
          <i className="material-icons about-icons">settings</i> sazlamalar
          sahypamyzda diňe iki bölim göriner, biri Bize agza bolmak bölimi,
          ikinjisi biz bilen hawarlaşmak bölimi bolar. Ýöne bize agza
          bolanyňyzdan soň bu sahypamyzda birnaçe bölimler açylar açylar, ol
          bölimler Sözlik öwrenmek hyzmatyndan ýa-da Arakesme, Hemaýatçylar,
          Biznes hyzmatlarymyzdan halan sahypaňyzy aýratyn kesip alyp öz
          Profeliňizde saklar.
        </p>
        <p>
          Agza bolmagyň usuly{" "}
          <i className="material-icons about-icons">settings</i> sazlamalar
          düwmesi Agza bol bölimini saýlaýas. Şol ýere gerekli maglumatlary
          dolduruň. Mysal üçin: Ulanyjy ady, Telefon nomeri, Açar sözi, Açar
          sözi täzeden we welaýatyňyzy girizmeli, Soňra maglumatlaryňyzy ýatda
          saklap Agza bol düwmä basýaňyz, we ýazan nomeriňizden 15minudyň
          dowamynda bellenilen nomere (+99364197532) sms ýollamaly, saýdyň
          operatory gelen maglumatlary barlap agza hasabyna geçirer we size
          birnäçe ýeňillikli mümkinçilikler açylar.
        </p>
        <p>
          Bize ozaldan agza bolun bolsaňyz{" "}
          <i className="material-icons about-icons">settings</i> sazlamalar
          sahypamyzyň agza bol böliminden içeri gir düwmesine basyp Ulanyjy
          adyňeze we Açar söziňizi ýazyp Agza bolup bilersiňiz.
        </p>
        <p>
          <i className="material-icons about-icons">settings</i> sazlamalar
          hyzmatymyzda biziň bilen habarlaşmak üçin telefon belgilerimiz we
          Täzeliklerimizi ildeşlerimize ýetirip durmak üçin köpçilikleýin
          ulanylýan platformalardaky salgylarymyz ýerleşdirlen.
        </p>
      </div>
    </div>
  );
}
