import React from "react";
import { useParams } from "react-router-dom";
import Card from "./cards/ProductCard";
import { ProductAdminCard } from "./admins/ProductAdmin";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import BannerCard from "./cards/BannerCard";
import { Carousel } from "./utils/SwipeUp";

export function Products() {
  const [products, setProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [velayats, setVelayats] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [marketCode, setMarketCode] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const [banners, setBanners] = React.useState([]);
  const next = React.useRef(null);
  const [favorites, setFavorites] = React.useState([]);
  const [showFavorites, setShowFavorites] = React.useState(false);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const message = React.useRef();
  const state = React.useRef();
  const [nextButtonClicked, setNextButtonClicked] = React.useState(false);

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/product", { headers: headers })
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        state.current = true;
        setProducts(response.data.results);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    // Get Categories
    axios
      .get("https://peydalan.com.tm/api/category?type=product")
      .then((response) => {
        // set the array state to the response data
        setCategories(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    // Get Categories
    axios
      .get("https://peydalan.com.tm/api/velayat?type=product")
      .then((response) => {
        // set the array state to the response data
        setVelayats(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    axios
      .get("https://peydalan.com.tm/api/service-ad")
      .then((response) => {
        state.current = true;
        setBanners(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const getNext = () => {
    setNextButtonClicked(true);
    axios
      .get(next.current)
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        state.current = true;
        setProducts((breaks) => [...breaks, ...response.data.results]);
        setNextButtonClicked(false);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  React.useEffect(() => {
    if (selectedCategory || selectedRegion) {
      axios
        .get(
          "https://peydalan.com.tm/api/product?category=" +
            selectedCategory +
            "&region=" +
            selectedRegion,
          { headers: headers }
        )
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }

          state.current = true;
          setProducts(response.data.results);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    }
  }, [selectedCategory, selectedRegion]);

  const selectCategory = (event) => {
    const item = categories.filter(
      (item) => item.id.toString() === event.target.value
    )[0];
    if (item && item.is_empty) {
      message.current =
        "Bu kategoriýada entek haryt goýulmadyk. Habarlaşmak: +99364197532";
      setPopupOpened(true);
    } else {
      setSelectedCategory(event.target.value);
    }
  };

  const selectRegion = (event) => {
    const item = velayats.filter(
      (item) => item.id.toString() === event.target.value
    )[0];
    if (item && item.is_empty) {
      message.current =
        "Bu welaýatda entek haryt ýa-da market goýulmadyk. Habarlaşmak: +99364197532";
      setPopupOpened(true);
    } else {
      setSelectedRegion(event.target.value);
    }
  };

  const addToFavorites = (id) => {
    const updatedData = products.map((item) => {
      if (item.id === id) {
        if (item.liked === true) {
          return { ...item, liked: false };
        } else {
          return { ...item, liked: true };
        }
      } else {
        return item;
      }
    });

    state.current = true;
    setProducts(updatedData);
  };

  function addObjectAfterEvery5Objects(array, object) {
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i % 5 == 0 && i !== 0) {
        if (array[i].banner !== true) {
          const randomIndex = Math.floor(Math.random() * object.length);
          newArray.push({ ...object[randomIndex], banner: true });
        }
      }
      newArray.push(array[i]);
    }
    return setProducts(newArray);
  }

  React.useEffect(() => {
    if (state.current) {
      state.current = false;
      addObjectAfterEvery5Objects(products, banners);
    }
  }, [products, banners]);

  React.useEffect(() => {
    axios
      .get(
        "https://peydalan.com.tm/api/product?code=" +
          marketCode.replace(".", ""),
        {
          headers: headers,
        }
      )
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }

        state.current = true;
        setProducts(response.data.results);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    if (marketCode != "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [marketCode]);

  const resetFilter = () => {
    setMarketCode("");
    setSelectedCategory("");
    setSelectedRegion("");
  };

  const changeMarketCode = (event) => {
    if (event.target.value.length === 3) {
      if (marketCode.length == 2) {
        setMarketCode(event.target.value + ".");
      } else {
        setMarketCode(event.target.value.slice(0, -1));
      }
    } else {
      setMarketCode(event.target.value);
    }
  };
  const toggleFavorite = () => {
    setShowFavorites(!showFavorites);
  };

  React.useEffect(() => {
    setFavorites(
      products.filter((object) => {
        return object.liked === true;
      })
    );
  }, [products]);

  return (
    <div className="text-frame">
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">{message.current}</div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                OK
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      <div>
        <h2>Harytlar</h2>
      </div>
      <div className="product-top">
        <select
          disabled={disabled}
          value={selectedRegion}
          className="break-select"
          onChange={(event) => {
            selectRegion(event);
          }}
        >
          <option value="">Welaýat Hemmesi</option>
          {velayats.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
        </select>
        <select
          disabled={disabled}
          className="break-select"
          value={selectedCategory}
          onChange={(event) => {
            selectCategory(event);
          }}
        >
          <option value={""}>Kategoriýa Hemmesi</option>
          {categories.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
        </select>
        <div className="break-select product-div  padding-0">
          <input
            value={marketCode}
            onChange={changeMarketCode}
            placeholder="Market Kody"
          />
          <span onClick={resetFilter}>
            <i className="material-icons">delete</i>
          </span>
        </div>
        <button
          className="break-select break-button  padding-0"
          onClick={toggleFavorite}
          disabled={!localStorage.getItem("token")}
        >
          <i className="material-icons" id="favorite-i">
            {showFavorites ? "favorite_border" : "favorite"}
          </i>
        </button>
      </div>
      {/* <Carousel items={products} /> */}
      {showFavorites
        ? favorites
            .reverse()
            .map(
              (product) =>
                product && (
                  <Card
                    key={product.id}
                    item={product}
                    addToFavorites={addToFavorites}
                  />
                )
            )
        : products.map((item, index) =>
            item.banner ? (
              <BannerCard key={index} banner={item} source={"products"} />
            ) : (
              <Card key={item.id} item={item} addToFavorites={addToFavorites} />
            )
          )}
      {next.current && !showFavorites && (
        <button
          className="button next-button"
          onClick={getNext}
          disabled={nextButtonClicked}
        >
          Indiki
        </button>
      )}
    </div>
  );
}

export function MyProducts() {
  const [products, setProducts] = React.useState([]);
  const next = React.useRef(null);
  const navigate = useNavigate();

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get(
        "https://peydalan.com.tm/api/product?code=" +
          localStorage.getItem("market"),
        { headers: headers }
      )
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setProducts(response.data.results);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const getNext = () => {
    axios
      .get(next.current)
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setProducts((breaks) => [...breaks, ...response.data.results]);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const refresh = () => {
    axios
      .get(
        "https://peydalan.com.tm/api/product?code=" +
          localStorage.getItem("market"),
        { headers: headers }
      )
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setProducts(response.data.results);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const addProduct = () => {
    navigate("/market/mine/add");
  };

  return (
    <div className="text-frame">
      <div>
        <h2>Harytlar</h2>
      </div>
      <div className="product-top">
        <button
          disabled={!localStorage.getItem("market")}
          className="break-select break-button"
          onClick={addProduct}
        >
          Haryt goş
        </button>
      </div>
      {products.map((item) => (
        <ProductAdminCard key={item.id} item={item} refresh={refresh} />
      ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}
