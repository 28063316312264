import React from "react";
import { Link } from "react-router-dom";

export default function DictionarySidebar(props) {
  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
    // setTimeout(() => {}, 1000);
  };

  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Sozluk Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "choose");
            }}
            to="/dictionary/choose"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "choose" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Kitap saýla</h5>
              <p>Kitap saýlamak üçin</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "text");
            }}
            to="/dictionary/1"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "text" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 1</h5>
              <p>Sözlükleriň sanawy</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "learn");
            }}
            to="/dictionary/2"
            className={
              props.menu === "learn"
                ? "filterDiscussions  single all unread active"
                : "filterDiscussions  single all unread "
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 2</h5>
              <p>Sözlügi göçürip öwrenmek</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "audio");
            }}
            to="/dictionary/3"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "lesson" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 3</h5>
              <p>Sözlügi sesli öwrenmek</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "test");
            }}
            to="/dictionary/4"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "test" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 4</h5>
              <p>Sözleri terjime et</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
