import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function MessageCard(props) {
  const [time, setTime] = React.useState(props.time);
  const navigate = useNavigate();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  } ${props.active ? "active" : ""}`;

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }
  const changeLyricTime = (action) => {
    var url = "https://peydalan.com.tm/api/lyrics/change-lyric-time";
    console.log(props.id);
    axios
      .post(url, { id: props.id, action: action }, { headers: headers })
      .then((res) => {
        console.log(res.data);
        if (action === "up") {
          setTime((prev) => prev + 1);
        } else {
          setTime((prev) => prev - 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={props.me ? "message me" : "message"}>
      <div className="text-main">
        <div
          className={
            props.me
              ? "text-group me"
              : props.active
              ? "text-group active"
              : "text-group"
          }
        >
          <div className={classes}>
            {props.ad ? (
              <div>
                <img
                  onClick={(e) => {
                    navigateToSponsor(e, props.sponsorId);
                  }}
                  src={props.message}
                />
                <div>
                  <i className="material-icons" id="ad-view-i">
                    visibility
                  </i>{" "}
                  {props.view}
                </div>
              </div>
            ) : (
              <div
                className={
                  props.active ? "lyric-message active" : "lyric-message"
                }
              >
                <p>{props.message}</p>
                {props.canChangeLyricTime &&
                  !props.me &&
                  (props.time || props.time === 0) && (
                    <p>
                      {time}{" "}
                      <button
                        onClick={() => {
                          changeLyricTime("up");
                        }}
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => {
                          changeLyricTime("down");
                        }}
                      >
                        ↓
                      </button>
                    </p>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
