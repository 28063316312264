import React from "react";

export default function TestCard(props) {
  const [selectedButton, setSelectedButton] = React.useState(0);
  const audioRef = React.useRef();
  const audio1Ref = React.useRef();
  const audio2Ref = React.useRef();
  const audio3Ref = React.useRef();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  React.useEffect(() => {
    if (props.audio) {
      audioRef.current.play().catch((error) => {
        console.error(error);
      });
    }
  }, []);

  const selectAnswer = (text, id) => {
    props.selectedSet(text);
    setSelectedButton(id);
  };

  const playAudio = () => {
    // Use the ref to access the audio element
    audioRef.current.play();
  };
  const playAudio1 = () => {
    // Use the ref to access the audio element
    audio1Ref.current.play();
  };
  const playAudio2 = () => {
    // Use the ref to access the audio element
    audio2Ref.current.play();
  };
  const playAudio3 = () => {
    // Use the ref to access the audio element
    audio3Ref.current.play();
  };
  // if (!props.message) {
  //   return;
  // }
  return (
    <div className="message" onClick={props.audio && playAudio}>
      <div className="text-main">
        <div className="text-group">
          <div className={classes}>
            {props.audio && (
              <audio
                ref={audioRef}
                src={"https://peydalan.com.tm" + props.audio}
                hidden
              />
            )}
            <h6>{props.message}</h6>
            {props.additional1 && <h6>{props.additional1}</h6>}

            <button
              className={
                selectedButton === 1
                  ? "answer-button selected"
                  : "answer-button"
              }
              onClick={(e) => {
                e.stopPropagation();
                playAudio1();
                selectAnswer(props.answer1, 1);
              }}
              disabled={props.disabled && true}
            >
              {props.answer1}
              {props.answer1_additional2 && <p>{props.answer1_additional2}</p>}
            </button>
            {props.audio1 && (
              <audio
                ref={audio1Ref}
                src={"https://peydalan.com.tm" + props.audio1}
                hidden
              />
            )}

            <button
              className={
                selectedButton === 2
                  ? "answer-button selected"
                  : "answer-button"
              }
              onClick={(e) => {
                e.stopPropagation();
                playAudio2();
                selectAnswer(props.answer2, 2);
              }}
              disabled={props.disabled && true}
            >
              {props.audio2 && (
                <audio
                  ref={audio2Ref}
                  src={"https://peydalan.com.tm" + props.audio2}
                  hidden
                />
              )}
              {props.answer2}
              {props.answer2_additional2 && <p>{props.answer2_additional2}</p>}
            </button>

            <button
              className={
                selectedButton === 3
                  ? "answer-button selected"
                  : "answer-button"
              }
              onClick={(e) => {
                e.stopPropagation();
                playAudio3();
                selectAnswer(props.answer3, 3);
              }}
              disabled={props.disabled && true}
            >
              {props.audio3 && (
                <audio
                  ref={audio3Ref}
                  src={"https://peydalan.com.tm" + props.audio3}
                  hidden
                />
              )}
              {props.answer3}
              {props.answer3_additional2 && <p>{props.answer3_additional2}</p>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
