import React from "react";
import Card from "./cards/MarketCard";
import axios from "axios";
import Popup from "reactjs-popup";

export function Markets() {
  const [markets, setMarkets] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [velayats, setVelayats] = React.useState([]);
  const [marketCode, setMarketCode] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const next = React.useRef(null);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const message = React.useRef();

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/market", { headers: headers })
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setMarkets(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    // Get Categories
    axios
      .get("https://peydalan.com.tm/api/category?type=market")
      .then((response) => {
        // set the array state to the response data
        setCategories(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    // Get Categories
    axios
      .get("https://peydalan.com.tm/api/velayat?type=market")
      .then((response) => {
        // set the array state to the response data
        setVelayats(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const getNext = () => {
    axios
      .get(next.current)
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setMarkets((breaks) => [...breaks, ...response.data.results]);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  React.useEffect(() => {
    axios
      .get(
        "https://peydalan.com.tm/api/market?category=" +
          selectedCategory +
          "&region=" +
          selectedRegion,
        { headers: headers }
      )
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setMarkets(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [selectedCategory, selectedRegion]);

  const selectCategory = (event) => {
    const item = categories.filter(
      (item) => item.id.toString() === event.target.value
    )[0];
    if (item && item.is_empty) {
      message.current =
        "Bu kategoriýada entek market goýulmadyk. Habarlaşmak: +99364197532";
      setPopupOpened(true);
    } else {
      setSelectedCategory(event.target.value);
    }
  };

  const selectRegion = (event) => {
    const item = velayats.filter(
      (item) => item.id.toString() === event.target.value
    )[0];
    if (item && item.is_empty) {
      message.current =
        "Bu welaýatda entek market goýulmadyk. Habarlaşmak: +99364197532";
      setPopupOpened(true);
    } else {
      setSelectedRegion(event.target.value);
    }
  };

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/market?code=" + marketCode, {
        headers: headers,
      })
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setMarkets(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    if (marketCode != "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [marketCode]);

  const resetFilter = () => {
    setMarketCode("");
    setSelectedCategory("");
    setSelectedRegion("");
  };

  const changeMarketCode = (event) => {
    setMarketCode(event.target.value);
  };

  return (
    <div className="text-frame">
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">{message.current}</div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                OK
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      <div>
        <h2>Marketler</h2>
      </div>
      <div className="product-top">
        <select
          disabled={disabled}
          value={selectedRegion}
          className="break-select"
          onChange={(event) => {
            selectRegion(event);
          }}
        >
          <option value="">Welaýat Hemmesi</option>
          {velayats.map((item) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select>
        <select
          disabled={disabled}
          className="break-select"
          value={selectedCategory}
          onChange={(event) => {
            selectCategory(event);
          }}
        >
          <option value={""}>Kategoriýa Hemmesi</option>
          {categories.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <div className="break-select product-div">
          <input
            value={marketCode}
            onChange={changeMarketCode}
            placeholder="Market Kody"
          />
          <button onClick={resetFilter} className="markets-delete-icon">
            <i className="material-icons">delete</i>
          </button>
        </div>
      </div>
      {markets.map((item) => (
        <Card key={item.id} item={item} />
      ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}
