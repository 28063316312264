import React from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

export default function Card(props) {
  const [opened, setOpened] = React.useState(false);
  const [current, setCurrent] = React.useState(1);
  const [favorite, setFavorite] = React.useState(props.item.liked);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const [popupFeedbackOpened, setPopupFeedbackOpened] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState("");
  const [message, setMessage] = React.useState({ message: "" });
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 3
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  const toggleFavorite = () => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };

      axios
        .get(
          "https://peydalan.com.tm/api/add-product-favorite?id=" +
            props.item.id,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setFavorite(response.data.liked);
          props.addToFavorites(props.item.id);
          // Handle the response, such as showing a success message
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setMessage({
        message: `Halaýanlaryňyza goşmak üçin ilki içeri giriň. Içeri girmek, bu
      posty ýatda saklamaga we soň aňsatlyk bilen görmäge mümkinçilik
      berer. Hasabyňyz ýok bolsa, mugt döredip bilersiňiz. Aşakdaky
      "Giriş" düwmesine basyň. Hasaba gireniňizden soň, postyň
      aşagyndaky ýürek nyşanyna basyp, bu sahypany halanýanlaryňyza
      goşup bilersiňiz.`,
        login: true,
      });
      setPopupOpened(true);
    }
  };

  const insertDot = (text) => {
    text = text.split("");
    text = text.slice(0, 3).join("") + "." + text.slice(3);
    return text.replace(",", "");
  };

  const sendFeedback = () => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };
      const url = "https://peydalan.com.tm/api/market/feedback";

      const formData = new FormData();
      formData.append("body", feedbackMessage);
      formData.append("product_id", props.item.id);

      axios
        .post(url, formData, {
          headers: headers,
        })
        .then((response) => {
          setMessage({ message: "Tekst ugradyldy" });
          setPopupOpened(true);
          setPopupFeedbackOpened(false);
          setFeedbackMessage("");
        })
        .catch((error) => {
          console.error("There was an error sending the image:", error);
        });
    } else {
      setMessage({
        message: `Halaýanlaryňyza goşmak üçin ilki içeri giriň. Içeri girmek, bu
      posty ýatda saklamaga we soň aňsatlyk bilen görmäge mümkinçilik
      berer. Hasabyňyz ýok bolsa, mugt döredip bilersiňiz. Aşakdaky
      "Giriş" düwmesine basyň. Hasaba gireniňizden soň, postyň
      aşagyndaky ýürek nyşanyna basyp, bu sahypany halanýanlaryňyza
      goşup bilersiňiz.`,
        login: true,
      });
      setPopupOpened(true);
    }
  };

  const toggleFeedback = () => {
    if (localStorage.getItem("token")) {
      setPopupFeedbackOpened(true);
    } else {
      setMessage({
        message: `Halaýanlaryňyza goşmak üçin ilki içeri giriň. Içeri girmek, bu
      posty ýatda saklamaga we soň aňsatlyk bilen görmäge mümkinçilik
      berer. Hasabyňyz ýok bolsa, mugt döredip bilersiňiz. Aşakdaky
      "Giriş" düwmesine basyň. Hasaba gireniňizden soň, postyň
      aşagyndaky ýürek nyşanyna basyp, bu sahypany halanýanlaryňyza
      goşup bilersiňiz.`,
        login: true,
      });
      setPopupOpened(true);
    }
  };

  return (
    <div className="text-content-frame">
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">{message.message}</div>
            <div className="actions">
              {message.login ? (
                <div>
                  <button
                    className="button button-login"
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    {" "}
                    Giriş{" "}
                  </button>
                  <button
                    className="button"
                    onClick={() => {
                      close();
                    }}
                  >
                    Ýok, Sagboluň
                  </button>
                </div>
              ) : (
                <button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  OK
                </button>
              )}
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      <Popup
        open={popupFeedbackOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupFeedbackOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">
              <textarea
                style={{ width: "100%", height: "200px" }}
                onChange={(e) => setFeedbackMessage(e.target.value)}
                value={feedbackMessage}
              ></textarea>
            </div>
            <div className="actions">
              <button
                className="button button-login button-login-mini"
                onClick={() => {
                  sendFeedback();
                }}
              >
                Ugrat
              </button>
              <button
                className="button button-login button-login-mini"
                onClick={() => {
                  close();
                }}
              >
                Goýbolsun etmek
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      {/* <img src={"https://peydalan.com.tm" + props.item.image_1} /> */}
      {!opened && props.item.image_1 && <img src={props.item.image_1} />}
      <div style={{ display: opened ? "block" : "none" }}>
        <div className="slideshow-container">
          {current == 1 && (
            <div>
              <div className="numbertext">1 / 3</div>
              <img src={props.item.image_1} className="slide-img" />
            </div>
          )}
          {current == 2 && (
            <div>
              <div className="numbertext">2 / 3</div>
              <img
                src={props.item.image_2 ? props.item.image_2 : props.item.image}
                className="img"
              />
            </div>
          )}
          {current == 3 && (
            <div>
              <div className="numbertext">3 / 3</div>
              <img
                src={props.item.image_3 ? props.item.image_3 : props.item.image}
                className="img"
              />
            </div>
          )}
          <a
            className="prev"
            onClick={() => {
              change(false);
            }}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => {
              change(true);
            }}
          >
            &#10095;
          </a>
        </div>
      </div>
      <table
        className={opened ? "product-table" : "product-table margin-bottom-px"}
      >
        <tbody>
          <tr className="product-row">
            <td className="product-title" colSpan={2}>
              Ady: <span>{props.item.name}</span>
            </td>
          </tr>
          <tr className="product-row">
            <td className="product-title product-price">
              Bahasy:{"  "}
              {props.item.price}
            </td>
            <td className="product-info product-price">
              Kody:{"  "}
              {insertDot(props.item.code.toString())}
            </td>
          </tr>

          {opened && (
            <tr className="product-row">
              <td className="product-title">Kategoriýasy:</td>
              <td className="product-info">
                {props.item.market.category.name}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {opened && <div className="product-about">{props.item.about}</div>}
      {opened && (
        <div className="product-market-info">
          {props.item.market.name}
          {"  "}
          {props.item.market.phone_number}
          {"  "}({props.item.market.code})
        </div>
      )}
      {opened && (
        <div className="product-market-info">
          {props.item.market.velayat.join(", ")} ({props.item.market.region})
        </div>
      )}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
      <button className="text-button favorite-button" onClick={toggleFavorite}>
        <i className="material-icons" id="favorite-i">
          {favorite ? "favorite" : "favorite_border"}
        </i>
      </button>
      <button className="text-button" onClick={toggleFeedback}>
        Nägilelik
      </button>
    </div>
  );
}
