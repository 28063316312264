import React from "react";
import MessageCard from "../cards/MessageCard";
import Select, { components } from "react-select";

export default function TestSentence(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [isTyping, setIsTyping] = React.useState(false);

  const [statistics, setStatistics] = React.useState({
    corrects: 0,
    errors: 0,
  });
  const [errored, setErrored] = React.useState(false);

  const [options, setOptions] = React.useState([]);
  const [choices, setChoices] = React.useState([]);
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const questions = React.useRef(false);
  // const [correctAnswer, setCorrectAnswer] = React.useState({});
  const correctAnswer = React.useRef(false);
  const correctArray = React.useRef([]);
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer

  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const welcomeMessage = React.useRef("");
  const input = React.useRef();

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    // const sortedArray1 = array1.slice().sort();
    // const sortedArray2 = array2.slice().sort();

    return array1.every(
      (element, index) => element.label === array2[index].label
    );
  };

  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };

  const getQuestions = (dialogs) => {
    var id = 0;
    var newArray = [
      dialogs.map((item) => {
        id += 1;
        return {
          id: id,
          question: item.question_another,
          audio: item.audio_question_another,
          turkmen: item.question_turkmen,
          audio_turkmen: item.audio_question_turkmen,
          additional1: item.additional1,
          answer_another: item.answer_another,
          answer_turkmen: item.answer_turkmen,
          slug: item.question_another_slug,
        };
      }),
    ][0];
    newArray = [
      ...newArray,
      // [
      ...dialogs.map((item) => {
        id += 1;
        return {
          id: id,
          question: item.answer_another,
          audio: item.audio_answer_another,
          turkmen: item.answer_turkmen,
          audio_turkmen: item.audio_answer_turkmen,
          additional1: item.additional2,
          answer_another: item.question_another,
          answer_turkmen: item.question_turkmen,
          slug: item.answer_another_slug,
        };
      }),
    ];
    return newArray;
  };

  const handleInputChange = (data) => {
    setChoices(data);
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (text, audio = null) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        me: false,
        info: true,
      },
    ]);
  };

  const sendMessage = (e) => {
    var flag;
    if (props.lesson.category === "english") {
      flag = "🇺🇸 ";
    } else if (props.lesson.category === "russian") {
      flag = "🇷🇺 ";
    } else if (props.lesson.category === "chinese") {
      flag = "🇨🇳";
    }

    if (choices.length !== 0) {
      var splitter = " ";
      var message = choices
        .map((obj) => {
          if (obj.label.split(" ").length > 1) {
            splitter = "";
          }
          return obj.label.split(" ")[0];
        })
        .join(splitter);
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (areArraysEqual(choices, correctArray.current)) {
        setOptions([]);
        if (!errored) {
          setStatistics({ ...statistics, corrects: statistics.corrects + 1 });
        } else {
          setStatistics({ ...statistics, errors: statistics.errors + 1 });
        }
        setErrored(false);
        setIsTyping(true);
        setTimeout(() => {
          playCorrectSound();
          sendInfo("👍 Dogry. Gepleşik içinde getirmege meselem: 👇️");
        }, 1000);

        setTimeout(() => {
          if (correctAnswer.current.additional1) {
            sendInfo(
              correctAnswer.current.answer_another +
                "\n" +
                correctAnswer.current.answer_turkmen +
                "\n" +
                correctAnswer.current.additional1
            );
          } else {
            sendInfo(
              "🇺🇸 " +
                correctAnswer.current.answer_another +
                "\n🇹🇲 " +
                correctAnswer.current.answer_turkmen
            );
          }
        }, 2500);

        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 4000); // send another question
      } else {
        setTimeout(() => {
          playWrongSound();
          setErrored(true);
          if (correctAnswer.current.additional1) {
            sendError(
              correctAnswer.current.question +
                "\n" +
                correctAnswer.current.additional1
            );
          } else {
            sendError(correctAnswer.current.question);
          }
        }, 1000);
      }
    }
    setChoices([]);
  };

  const sendQuestion = () => {
    if (questions.current.length > 0) {
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: question.turkmen,
          // additional1: question.additional1,
          // additional3: question.additional1,
          me: false,
        },
      ]);
      const shuffledObjects = shuffleArray(
        question.slug
          .split("_")
          .map((element, index) => ({ label: element, value: index }))
      );
      setOptions(shuffledObjects);
      correctAnswer.current = question;
      correctArray.current = question.slug
        .split("_")
        .map((element, index) => ({ label: element, value: index })); // add the question to the dialog
    }
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer).length !== 0) {
        setTimeout(() => {
          sendInfo(
            "Berekella, indiki aylawymyzy dowam edelin \nDogry sany: " +
              statistics.corrects +
              "\nÝalňyş sany:" +
              statistics.errors
          );
        }, 1000);
        setTimeout(() => {
          const data = getQuestions(props.dialogs);
          questions.current = data;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (props.lesson) {
      var language = "";
      if (props.lesson.category === "english") {
        language = "Iňlis dili";
      } else if (props.lesson.category === "russian") {
        language = "Rus dili";
      } else if (props.lesson.category === "chinese") {
        language = "Hytaý dili";
      }
      welcomeMessage.current =
        "Salam! " +
        language +
        " öwrenmegiň 6-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulylymyz 4-nji adimde öwren sözlemlermizi terjime etmek ukybymyzy artdyraly, yagny men size türkmen dilinde ýazylan sözlemlerimizi ugradaryn, siz welin 4-nji adimde öwrenen terjimäňizi ýatlap ýazyňda barlaga ugradyň, bu sapagyň netijesi siziň öwreniş netijäňiz bolar. Good Luck!";
    }
  }, [props.lesson]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          onTouchEnd: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
      >
        <i
          onMouseDown={() => sendMessage()}
          onTouchEnd={() => sendMessage()}
          className="material-icons"
        >
          send
        </i>
      </components.DropdownIndicator>
    );
  };

  React.useEffect(() => {
    if (!props.ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: props.ad.image,
          me: false,
          ad: true,
          view: props.ad.view,
          id: props.ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [props.ad]);

  React.useEffect(() => {
    if (props.dialogs.length !== 0) {
      const data = getQuestions(props.dialogs);
      questions.current = data;
    }
    // Set the state with the data
  }, [props.dialogs]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                ad={item.ad}
                view={item.view}
                sponsorId={item.id}
                message={item.message}
                additional1={item.additional1}
                setInputFocus={setInputFocus}
                additional2={item.additional2}
                additional3={item.additional3}
                audio={item.audio}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <Select
                defaultMenuIsOpen
                components={{ DropdownIndicator }}
                closeMenuOnSelect={false}
                isMulti
                options={options}
                ref={input}
                value={choices}
                onChange={handleInputChange}
                classNamePrefix="react-select"
                hideSelectedOptions={false}
                menuIsOpen={true}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
