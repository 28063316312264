import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";
import MessageCard from "../cards/MessageCard";
import { useParams } from "react-router-dom";
import TextCard from "../cards/DictionaryTextCard";
import AudioMessage from "../utils/AudioMessage";

export function ChooseGrade() {
  const [grades, setGrades] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/dictionary/grades/")
      .then((res) => {
        setGrades(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("dictionary_grade", button_name);

    navigate("/dictionary/chooseLevel/" + button_name);
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Sözlük</h4>
      {grades.map((item, index) => {
        return (
          <button
            key={index}
            className="test-button"
            onClick={() => {
              handleClick(item);
            }}
          >
            {item}
          </button>
        );
      })}
      <br />
      <h5>Düşündiriş teksti</h5>
      <p></p>
    </div>
  );
}

export function ChoosePageRange() {
  const [levels, setLevels] = React.useState([]);
  const { book_name } = useParams();

  React.useEffect(() => {
    axios
      .get(
        "https://peydalan.com.tm/api/dictionary/page_ranges?book_name=" +
          book_name
      )
      .then((res) => {
        setLevels(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("dictionary_page_range", button_name);
    navigate("/dictionary/1");
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Sözlük</h4>
      {levels.map((item, index) => {
        return (
          <button
            key={index}
            className="test-button"
            onClick={() => {
              handleClick(item);
            }}
          >
            {item}
          </button>
        );
      })}
      <br />
      <h5>Düşündiriş teksti</h5>
      <p></p>
    </div>
  );
}

export function DictionaryText(props) {
  const [ad, setAd] = React.useState([]);
  const [dialog, setDialog] = React.useState([{}]);
  const [isTyping, setIsTyping] = React.useState(false);
  const [selectedWord, setSelectedWord] = React.useState({});
  const messagesEndRef = React.useRef(null);
  const text_store = React.useRef(false);
  const texts = React.useRef(false);
  const welcomeMessage = React.useRef(
    "Sözleri bot bilen öwrenmek üçin menýudan ädimleri peýdalanyň"
  );
  const words = React.useRef([]);
  const audioRef = React.useRef();

  React.useEffect(() => {
    const book_name = localStorage.getItem("dictionary_grade");
    const lesson_name = localStorage.getItem("dictionary_page_range");
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    axios
      .get(
        "https://peydalan.com.tm/api/dictionary/texts/?book_name=" +
          book_name +
          "&lesson_name=" +
          lesson_name
      )
      .then((response) => {
        texts.current = response.data;
        text_store.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    if (texts.current.length > 0) {
      texts.current.forEach((item, index) => {
        axios
          .get(
            "https://peydalan.com.tm/api/dictionary/get-words-of-text?text_id=" +
              item.id
          )
          .then((response) => {
            // Assuming words.current is initialized as an array
            words.current = words.current.concat(response.data);
            console.log(response.data);
          })
          .catch((error) => {
            // Handle error
            console.error(error);
          });
      });
    }
  }, [texts.current]);

  const searchWord = (word) => {
    const searchTerm1 = word.toLowerCase();
    const filteredWords = words.current.filter((item) => {
      const translation = item.translation.toLowerCase();
      return translation === searchTerm1;
    });

    if (filteredWords.length === 0) {
      // Not Found
    } else {
      setSelectedWord({
        turkmen: filteredWords[0].turkmen,
        translation: filteredWords[0].translation,
        info: filteredWords[0].text,
      });
      audioRef.current.src =
        "http://peydalan.com.tm" + filteredWords[0].audio_translation;
      audioRef.current.play();
    }
  };

  const sendText = () => {
    if (texts.current.length > 0) {
      const text = texts.current[0];
      setIsTyping(false);
      // get the question at that index
      let newArray = [];
      texts.current.map((item, index) => {
        newArray.push({
          question: true,
          id: item.id,
          message: item.text,
          button: "Indiki",
          me: false,
        });
      });
      setDialog((dialogs) => [...dialogs, ...newArray]); // add the question to the dialog
    } else {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: "Bu sapak tamamlandy. Indiki sapagymyza garashyarys",
          me: false,
          info: true,
        },
      ]);
    }
  };

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendText();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio hidden ref={audioRef} />
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.question ? (
                <TextCard
                  key={index}
                  message={item.message}
                  id={item.id}
                  selectedWord={selectedWord}
                  audio={item.audio}
                  callback={searchWord}
                />
              ) : (
                <MessageCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <div
              style={{
                height: "100px",
                width: "100%",
                color: "var(--text-secondary)",
              }}
            >
              <audio ref={audioRef} />
              <h5>{selectedWord.translation}</h5>
              <h5>{selectedWord.turkmen}</h5>
              <h5>{selectedWord.info}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const slugify = (text) => {
  return (
    text
      .toString()
      // .normalize("NFKD")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "")
      .replace(/[.!?,]/g, "")
      .replace(/\-\-+/g, "")
      .replace(/;/g, "")
      .replace(/'/g, "")
      .replace(/"/g, "")
      .replace(/,/g, "")
  );
};

export function DictionaryLearnWord(props) {
  const [dialog, setDialog] = React.useState([{}]);
  const [ad, setAd] = React.useState({});

  const [isTyping, setIsTyping] = React.useState(false);

  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const questions = React.useRef(false);
  const questions_backup = React.useRef(false);
  // const [correctAnswer, setCorrectAnswer] = React.useState({});
  const correctAnswer = React.useRef(false);
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer
  const interval = React.useRef(false);
  const input = React.useRef();
  const welcomeMessage = React.useRef("Sözlügi göçürip öwrenmek");
  const audioRef = React.useRef();

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (
    text,
    additional1,
    additional2,
    additional3,
    audio = null
  ) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        additional1: additional1,
        additional2: additional2,
        additional3: additional3,
        me: false,
        info: true,
      },
    ]);
    if (audio) {
      audioRef.current.src = "https://peydalan.com.tm" + audio;
      audioRef.current.play();
    }
  };

  React.useEffect(() => {
    const book_name = localStorage.getItem("dictionary_grade");
    const lesson_name = localStorage.getItem("dictionary_page_range");

    let headers;
    if (localStorage.getItem("token")) {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
    }

    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    axios
      .get(
        "https://peydalan.com.tm/api/dictionary/words/?book_name=" +
          book_name +
          "&lesson_name=" +
          lesson_name,
        {
          headers: headers,
        }
      )
      .then((response) => {
        questions.current = response.data;
        questions_backup.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const sendMessage = (e) => {
    if (message && message !== "\n") {
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (
        slugify(message.toLowerCase()) ===
        slugify(correctAnswer.current.translation.toLowerCase())
      ) {
        setIsTyping(true);
        setTimeout(() => {
          sendInfo(
            correctAnswer.current.turkmen,
            "",
            "",
            "",
            correctAnswer.current.audio_turkmen
          );
        }, 1000);

        setTimeout(() => {
          sendInfo(correctAnswer.current.text);
        }, 2000);

        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 3500); // send another question
      } else {
        setTimeout(() => {
          sendError(correctAnswer.current.translation);
        }, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const sendQuestion = () => {
    if (questions.current.length > 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          audio: question.audio_translation,
          additional1: question.text,
          message: question.translation,
          me: false,
        },
      ]);
      correctAnswer.current = question; // add the question to the dialog
      if (question.audio_another) {
        audioRef.current.src =
          "https://peydalan.com.tm" + question.audio_another;
        var playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch((e) => {
              console.error(e);
            });
        }
      }
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          sendQuestion();
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer.current).length !== 0) {
        setTimeout(() => {
          sendInfo("Berekella, indiki aylawymyzy dowam edelin");
        }, 1000);
        setTimeout(() => {
          questions.current = questions_backup.current;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio hidden ref={audioRef} />
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                audioRef={audioRef}
                ad={item.ad}
                view={item.view}
                setInputFocus={setInputFocus}
                sponsorId={item.id}
                message={item.message}
                additional1={item.additional1}
                additional2={item.additional2}
                additional3={item.additional3}
                audio={item.audio}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage();
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DictionaryTestWord(props) {
  const [dialog, setDialog] = React.useState([{}]);
  const [ad, setAd] = React.useState();
  const [statistics, setStatistics] = React.useState({
    corrects: 0,
    errors: 0,
  });
  const [correctWords, setCorrectWords] = React.useState([]);
  const [wrongWords, setWrongWords] = React.useState([]);
  const [errored, setErrored] = React.useState(false);

  const [isTyping, setIsTyping] = React.useState(false);

  const [message, setMessage] = React.useState("");
  const questions = React.useRef(false);
  const questions_backup = React.useRef(false);
  const correctAnswer = React.useRef(false);
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const welcomeMessage = React.useRef("Sözlügi sesli öwrenmek");
  const input = React.useRef();

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (text, audio = null) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        me: false,
        info: true,
      },
    ]);
  };

  React.useEffect(() => {
    const book_name = localStorage.getItem("dictionary_grade");
    const lesson_name = localStorage.getItem("dictionary_page_range");

    let headers;
    if (localStorage.getItem("token")) {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
    }

    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    axios
      .get(
        "https://peydalan.com.tm/api/dictionary/words/?book_name=" +
          book_name +
          "&lesson_name=" +
          lesson_name,
        {
          headers: headers,
        }
      )
      .then((response) => {
        questions.current = response.data;
        questions_backup.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const sendMessage = (e) => {
    if (message && message !== "\n") {
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (
        slugify(message.toLowerCase()) ===
        slugify(correctAnswer.current.translation.toLowerCase())
      ) {
        if (!errored) {
          setStatistics({ ...statistics, corrects: statistics.corrects + 1 });
          setCorrectWords((prev) => [...prev, correctAnswer.current.id]);
        } else {
          setStatistics({ ...statistics, errors: statistics.errors + 1 });
          setWrongWords((prev) => [...prev, correctAnswer.current.id]);
        }
        setIsTyping(true);
        setTimeout(() => {
          playCorrectSound();
          sendInfo("👍 Dogry. Sözlem içinde getirmege meselem: 👇️");
        }, 1000);

        setTimeout(() => {
          sendInfo(correctAnswer.current.text);
        }, 2000);
        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 3500); // send another question
      } else {
        playWrongSound();
        setErrored(true);
        setTimeout(() => {
          sendError(correctAnswer.current.translation);
        }, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const sendQuestion = () => {
    if (questions.current.length > 0) {
      setErrored(false);
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: question.turkmen,
          me: false,
        },
      ]);
      correctAnswer.current = question; // add the question to the dialog
    }
  };

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer.current).length !== 0) {
        setTimeout(() => {
          sendInfo(
            "Berekella, indiki aylawymyzy dowam edelin \nDogry sany: " +
              statistics.corrects +
              "\nÝalňyş sany:" +
              statistics.errors
          );
          setStatistics({ corrects: 0, errors: 0 });
        }, 1000);
        setTimeout(() => {
          questions.current = questions_backup.current;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <audio
          ref={audioObject}
          hidden
          src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
        ></audio>
        <audio
          ref={audioObject2}
          hidden
          src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
        ></audio>
        <div className="container">
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                ad={item.ad}
                view={item.view}
                sponsorId={item.id}
                message={item.message}
                setInputFocus={setInputFocus}
                additional1={item.additional1}
                additional2={item.additional2}
                additional3={item.additional3}
                audio={item.audio}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage(e);
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DictionaryAudioWord(props) {
  const [dialog, setDialog] = React.useState([{}]);
  const [ad, setAd] = React.useState();
  const [statistics, setStatistics] = React.useState({
    corrects: 0,
    errors: 0,
  });

  const [errored, setErrored] = React.useState(false);

  const [isTyping, setIsTyping] = React.useState(false);

  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const questions = React.useRef(false);
  const questions_backup = React.useRef(false);
  // const [correctAnswer, setCorrectAnswer] = React.useState({});
  const correctAnswer = React.useRef(false);
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer

  const input = React.useRef();
  const interval = React.useRef(false);
  const welcomeMessage = React.useRef("Sözleri terjime et");
  const audioRef = React.useRef();

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (text, audio = null) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        me: false,
        info: true,
      },
    ]);
    if (audio) {
      audioRef.current.src = "https://peydalan.com.tm" + audio;
      audioRef.current.play();
    }
  };

  React.useEffect(() => {
    const book_name = localStorage.getItem("dictionary_grade");
    const lesson_name = localStorage.getItem("dictionary_page_range");

    let headers;
    if (localStorage.getItem("token")) {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
    }

    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    axios
      .get(
        "https://peydalan.com.tm/api/dictionary/words/?book_name=" +
          book_name +
          "&lesson_name=" +
          lesson_name,
        {
          headers: headers,
        }
      )
      .then((response) => {
        questions.current = response.data;
        questions_backup.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const sendMessage = (e) => {
    if (message && message !== "\n") {
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (
        slugify(message.toLowerCase()) ===
        slugify(correctAnswer.current.translation.toLowerCase())
      ) {
        if (!errored) {
          setStatistics({ ...statistics, corrects: statistics.corrects + 1 });
        } else {
          setStatistics({ ...statistics, errors: statistics.errors + 1 });
        }
        setIsTyping(true);
        setTimeout(() => {
          sendInfo(
            correctAnswer.current.turkmen,
            correctAnswer.current.audio_turkmen
          );
        }, 1000);

        setTimeout(() => {
          sendInfo(correctAnswer.current.text);
        }, 2000);

        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 3500); // send another question
      } else {
        setErrored(true);
        setTimeout(() => {
          sendError(correctAnswer.current.translation);
        }, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  const sendQuestion = () => {
    if (questions.current.length > 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      setErrored(false);
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          audio: question.audio_translation,
          message: question.translation,
          me: false,
          audioMessage: true,
        },
      ]);
      correctAnswer.current = question; // add the question to the dialog
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          sendQuestion();
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer.current).length !== 0) {
        setTimeout(() => {
          sendInfo(
            "Berekella, indiki aylawymyzy dowam edelin \nDogry sany: " +
              statistics.corrects +
              "\nÝalňyş sany:" +
              statistics.errors
          );
          setStatistics({ corrects: 0, errors: 0 });
        }, 1000);
        setTimeout(() => {
          questions.current = questions_backup.current;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  // React.useEffect(, [questions])

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.audioMessage ? (
                <AudioMessage
                  key={index}
                  setInputFocus={setInputFocus}
                  audio={"https://peydalan.com.tm" + item.audio}
                />
              ) : (
                <MessageCard
                  key={index}
                  audioRef={audioRef}
                  setInputFocus={setInputFocus}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage(e);
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
