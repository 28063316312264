import axios from "axios";
import React from "react";

import LearnWord from "./lessons/LearnWord";
import AudioWord from "./lessons/AudioWord";
import TestWord from "./lessons/TestWord";

export default function LessonWord(props) {
  const [lesson, setLesson] = React.useState();
  const [ad, setAd] = React.useState([]);
  const [words, setWords] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/lesson?id=" + props.lessonId)
      .then((response) => {
        // set the array state to the response data
        setLesson(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [props.lessonId]);

  React.useEffect(() => {
    if (!lesson) {
      return;
    }
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    axios
      .get("https://peydalan.com.tm/api/word?lesson=" + lesson.id)
      .then((response) => {
        // set the array state to the response data
        setWords(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [lesson]);

  React.useEffect(() => {
    localStorage.setItem("lesson", props.lessonId);
  }, [props.lessonId]);

  if (props.target === "learn") {
    return (
      <LearnWord
        words={words}
        ad={ad}
        lesson={lesson}
        windowSize={props.windowSize}
        inputFocusedSet={props.inputFocusedSet}
        inputFocused={props.inputFocused}
      />
    );
  } else if (props.target === "audio") {
    return (
      <AudioWord
        words={words}
        ad={ad}
        lesson={lesson}
        windowSize={props.windowSize}
        inputFocusedSet={props.inputFocusedSet}
        inputFocused={props.inputFocused}
      />
    );
  } else if (props.target === "test") {
    return (
      <TestWord
        words={words}
        ad={ad}
        lesson={lesson}
        windowSize={props.windowSize}
        inputFocusedSet={props.inputFocusedSet}
        inputFocused={props.inputFocused}
      />
    );
  }
}
