import React from "react";
import { useNavigate } from "react-router-dom";

export default function AlphabetMessageCard(props) {
  const navigate = useNavigate();
  const audioRef = React.useRef();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  const playAudio = () => {
    audioRef.current.src = props.question.audio;
    try {
      props.setInputFocus();
      audioRef.current.play();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div
      className={props.me ? "message me" : "message"}
      onClick={props.question && playAudio}
    >
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          {props.chat && props.me && props.menuOpened === props.id && (
            <div
              className="dropdown-menu dropdown-menu-right show"
              // x-placement="bottom-end"
            >
              <button
                className="dropdown-item"
                onClick={(e) => {
                  props.deleteCallback(props.id);
                }}
              >
                <i className="material-icons">delete</i>Poz
              </button>
            </div>
          )}
          <div className={classes}>
            {props.question && (
              <audio
                ref={audioRef}
                src={props.question.audio}
                hidden
                autoPlay
              />
            )}
            {props.ad ? (
              <div>
                <img
                  onClick={(e) => {
                    navigateToSponsor(e, props.sponsorId);
                  }}
                  src={props.message}
                />
                <div>
                  <i className="material-icons" id="ad-view-i">
                    visibility
                  </i>{" "}
                  {props.view}
                </div>
              </div>
            ) : (
              <div>
                {props.question ? (
                  <img
                    src={props.question.image}
                    onLoad={props.scrollToBottom}
                  ></img>
                ) : (
                  <p>{props.message}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
