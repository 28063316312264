import React from "react";
import { Link } from "react-router-dom";

export default function AlphabetSidebar(props) {
  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Harplyk</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "level-1");
            }}
            to="/alphabet/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-1" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Dil Sayla</h5>
              <p>Owrenemk Dil saylamak</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-2");
            }}
            to="/alphabet/2"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-2" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 1</h5>
              <p>Harplyk öwrenmek</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-3");
            }}
            to="/alphabet/3"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-3" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 2</h5>
              <p>Surata seredip harpy tanamak</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-4");
            }}
            to="/alphabet/4"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-4" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 3</h5>
              <p>Harpa seredip surat tanamak</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
