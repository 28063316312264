import React from "react";
import { useNavigate } from "react-router-dom";

export default function MessageCard(props) {
  const audioRef = React.useRef();
  const mountedRef = React.useRef(false);
  const navigate = useNavigate();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  return (
    <div className={props.me ? "message me" : "message"}>
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          <div className={classes}>
            {props.ad ? (
              <div>
                <img
                  onClick={(e) => {
                    navigateToSponsor(e, props.sponsorId);
                  }}
                  src={props.message}
                />
                <div>
                  <i className="material-icons" id="ad-view-i">
                    visibility
                  </i>{" "}
                  {props.view}
                </div>
              </div>
            ) : (
              <div className="geography-message">
                {props.additional3 && <p>{props.additional1}</p>}
                <p>{props.message}</p>
                {props.map && (
                  <img
                    src={props.map}
                    style={{ width: "90%", marginTop: "10px" }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
