import {
  Routes,
  Route,
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import "./dist/css/lib/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "./dist/css/swipe.min.css";
import "reactjs-popup/dist/index.css";
// Default theme. ~960B
import "@vime/core/themes/default.css";
// Optional light theme (extends default). ~400B
// import "@vime/core/themes/light.css";
import "./dist/profile.css";
import "./App.css";

import { Notifications } from "react-push-notification";
import Navigation from "./components/Navigation";
import AboutSidebar from "./components/sidebars/AboutSidebar";
import AboutWebsite from "./components/AboutWebsite";
import {
  AboutSettings,
  AboutLesson,
  AboutSponsors,
  AboutMath,
} from "./components/About";
import {
  Login,
  Contact,
  PasswordReset,
  OauthRedirect,
} from "./components/Login";
import { Profile, Logout, UpdateProfile } from "./components/Profile";
import { Products, MyProducts } from "./components/Products";
import { Services, MyServices, ServiceDetail } from "./components/Services";
import { Markets } from "./components/Markets";
import {
  ProductAddForm,
  ProductEditForm,
} from "./components/admins/ProductAdmin";
import { ChooseKaraokeLanguagee, Karaoke } from "./components/games/Karaoke";
import GeographySidebar from "./components/sidebars/GeographySidebar";
import LessonSidebar from "./components/sidebars/LessonSidebar";
import MathSidebar from "./components/sidebars/MathSidebar";
import SponsorSidebar from "./components/sidebars/SponsorSidebar";
import MarketSidebar from "./components/sidebars/MarketSidebar";
import GameSidebar from "./components/sidebars/GamesSidebar";
import ChemistrySidebar from "./components/sidebars/ChemistrySidebar";
import ChatSidebar from "./components/sidebars/ChatSidebar";
import AlphabetSidebar from "./components/sidebars/AlphabetSidebar";
import KaraokeSidebar from "./components/sidebars/KaraokeSidebar";
import ProgressSidebar from "./components/sidebars/ProgressSidebar";
import React from "react";
import axios from "axios";
import { ChooseLesson, Text, TestText } from "./components/Lesson";
import {
  ServiceAddForm,
  ServiceEditForm,
} from "./components/admins/ServiceAdmin";
import { Games } from "./components/Games";

import {
  Sponsors,
  MajorSponsors,
  MajorSponsorDetail,
  Ads,
  MySponsors,
} from "./components/Sponsors";

import { Chat } from "./components/Chat";

import { ChooseLevel, Level } from "./components/games/Math";
import LessonWord from "./components/LessonWords";
import LessonSentence from "./components/LessonSentences";
import LessonTest from "./components/LessonTest";
import Badge from "./components/utils/Badge";
import {
  SponsorAddForm,
  SponsorEditForm,
} from "./components/admins/SponsorAdmin";
import { ChooseGame, Level1 } from "./components/games/Geography";
import {
  ChooseChemistryGame,
  ChemistryLevel,
} from "./components/games/Chemistry";
import { Blitz, ChooseBlitzTimeout } from "./components/games/Blitz";
import BlitzSidebar from "./components/sidebars/BlitzSidebar";
import {
  ChooseOperationTimeout,
  Operation,
} from "./components/games/Operation";
import OperationSidebar from "./components/sidebars/OperationSidebar";
import {
  AlphabetLevel,
  AlphabetLevel2,
  ChooseAlphabetLanguage,
} from "./components/games/Alphabet";
import { Feedbacks } from "./components/Feedbacks";
import { Progress, TopUsers } from "./components/Progress";
import {
  ChooseGrade,
  ChoosePageRange,
  DictionaryText,
  DictionaryLearnWord,
  DictionaryTestWord,
  DictionaryAudioWord,
} from "./components/games/Dictionary";
import DictionarySidebar from "./components/sidebars/DictionarySidebar";

function App() {
  const [theme, setTheme] = React.useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  const [notifications, setNotifications] = React.useState({
    sponsors: 0,
    sponsors_small: 0,
    sponsors_major: 0,
    sponsors_ads: 0,
  });

  const [menuChecked, setMenuChecked] = React.useState(false);
  const [menuBadge, setMenuBadge] = React.useState(false);

  const [navigation, setNavigation] = React.useState();
  const [menu, setMenu] = React.useState();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [contactList, setContactList] = React.useState([]);
  const [chatNotificationList, setChatNotificationList] = React.useState([]);
  const [lessonId, setLessonId] = React.useState(
    localStorage.getItem("lesson")
  );
  const [inputFocused, setInputFocused] = React.useState(false);
  const [karaokeSidebarItems, setKaraokeSidebarItems] = React.useState([]);

  const inputFocusedSet = (value) => {
    setInputFocused(value);
  };

  const lessonIdSet = (lessonid) => {
    setLessonId(lessonid);
  };

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Define a function to update the state variable on window resize
  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    setMenuChecked(false);
  }, [menu]);

  // // Use the useEffect hook to add and remove the event listener
  React.useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };

      axios
        .get("https://peydalan.com.tm/api/notifications", {
          headers: headers,
        })
        .then((response) => {
          setNotifications(response.data);
          // Handle the response, such as showing a success message
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return;
  }, []);

  const navigationset = (value) => {
    setNavigation(value);
  };

  const menuBadgeSet = (value) => {
    setMenuBadge(value);
  };

  const notificationsSet = (value) => {
    setNotifications(value);
  };

  React.useEffect(() => {
    localStorage.setItem("lesson", lessonId);
  }, [lessonId]);

  const setChecked = () => {
    setMenuChecked(!menuChecked);
    document.getElementById("scrolltop").scrollIntoView();
  };

  const aboutsidebar = (
    <AboutSidebar
      setChecked={setChecked}
      navigation={navigation}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const geographysidebar = (
    <GeographySidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const lessonsidebar = (
    <LessonSidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const mathsidebar = (
    <MathSidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const sponsorsidebar = (
    <SponsorSidebar
      notifications={notifications}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const marketSidebar = (
    <MarketSidebar
      notifications={notifications}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const gameSidebar = (
    <GameSidebar
      notifications={notifications}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const chemistrySidebar = (
    <ChemistrySidebar
      notifications={notifications}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const blitzSidebar = (
    <BlitzSidebar
      notifications={notifications}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const operationSidebar = (
    <OperationSidebar
      notifications={notifications}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );
  const chatSidebar = (
    <ChatSidebar
      chatNotificationList={chatNotificationList}
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      isAdmin={isAdmin}
      contactList={contactList}
      setMenu={setMenu}
    />
  );
  const alphabetSidebar = (
    <AlphabetSidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
    />
  );

  const karaokeSidebar = (
    <KaraokeSidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
      karaokeSidebarItems={karaokeSidebarItems}
    />
  );

  const progressSidebar = (
    <ProgressSidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
      karaokeSidebarItems={karaokeSidebarItems}
    />
  );

  const dictionarySidebar = (
    <DictionarySidebar
      setChecked={setChecked}
      navigationset={navigationset}
      menu={menu}
      setMenu={setMenu}
      karaokeSidebarItems={karaokeSidebarItems}
    />
  );

  const SideBar = () =>
    useRoutes([
      { path: "/about/*", element: aboutsidebar },
      { path: "/profile", element: aboutsidebar },
      { path: "/oauth/login", element: aboutsidebar },
      { path: "/password-reset", element: aboutsidebar },
      { path: "/settings/*", element: aboutsidebar },
      { path: "/geography/*", element: geographysidebar },
      { path: "/", element: lessonsidebar },
      { path: "/lesson/*", element: lessonsidebar },
      { path: "/games", element: gameSidebar },
      { path: "/math/*", element: mathsidebar },
      { path: "/sponsors/*", element: sponsorsidebar },
      { path: "/market/*", element: marketSidebar },
      { path: "/chemistry/*", element: chemistrySidebar },
      { path: "/blitz/*", element: blitzSidebar },
      { path: "/operation/*", element: operationSidebar },
      { path: "/chat/*", element: chatSidebar },
      { path: "/alphabet/*", element: alphabetSidebar },
      { path: "/karaoke/*", element: karaokeSidebar },
      { path: "/progress/*", element: progressSidebar },
      { path: "/dictionary/*", element: dictionarySidebar },
    ]);

  return (
    <main data-theme={theme}>
      <Notifications />
      <Router>
        <div className="layout" data-theme={theme}>
          <Navigation
            theme={theme}
            menuBadgeSet={menuBadgeSet}
            notifications={notifications}
            chatNotificationList={chatNotificationList}
            windowSize={windowSize}
            navigation={navigation}
            setMenu={setMenu}
            inputFocused={inputFocused}
            onClick={() => {
              setMenuChecked(false);
            }}
          />
          {/* <!-- End of Navigation -->
				<!-- Start of Sidebar --> */}
          <div
            className="sidebar"
            id="sidebar"
            style={{
              height:
                windowSize.width > 991 ? "100%" : menuChecked ? "100%" : "0px",
            }}
            data-theme={theme}
          >
            <span id="scrolltop"></span>
            <input
              type="checkbox"
              id="menu"
              checked={menuChecked && "checked"}
              onChange={setChecked}
            />
            <label htmlFor="menu" className="icon">
              {menuBadge === "chat" && chatNotificationList.length > 0 && (
                <Badge count={chatNotificationList.length} place="menu" />
              )}
              {menuBadge === "sponsor" && notifications.sponsors > 0 && (
                <Badge count={notifications.sponsors} place="menu" />
              )}
              <div className="menu"></div>
            </label>
            <div className="toggle-btn" id="_1st-toggle-btn">
              <input
                type="checkbox"
                checked={theme === "dark" && true}
                onChange={switchTheme}
              />
              <span></span>
            </div>
            <div className="container sidebar-menu">
              <div className="col-md-12">
                <div className="tab-content">
                  {/* <!-- Start of Contacts --> */}
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
          <div className="main">
            <div className="tab-content" id="nav-tabContent">
              {/* <!-- Start of Babble --> */}
              <div
                className="babble tab-pane fade active show"
                id="list-chat"
                role="tabpanel"
                aria-labelledby="list-chat-list"
              >
                {/* <!-- Start of Chat --> */}
                <Routes>
                  <Route path="/about" element={<AboutWebsite />} />
                  <Route path="/about/settings" element={<AboutSettings />} />
                  <Route path="/about/lesson" element={<AboutLesson />} />
                  <Route path="/about/math" element={<AboutMath />} />
                  <Route path="/about/sponsors" element={<AboutSponsors />} />
                  <Route path="/settings" element={<Login />} />
                  <Route path="/settings/contact" element={<Contact />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/profile/update" element={<UpdateProfile />} />
                  <Route path="/password-reset" element={<PasswordReset />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route
                    path="/lesson"
                    element={
                      <Text
                        inputFocusedSet={inputFocusedSet}
                        lessonId={lessonId}
                        windowSize={windowSize}
                      />
                    }
                  />
                  <Route
                    path="/lesson/word"
                    element={
                      <LessonWord
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="learn"
                      />
                    }
                  />
                  <Route
                    path="/lesson/word/audio"
                    element={
                      <LessonWord
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="audio"
                      />
                    }
                  />
                  <Route
                    path="/lesson/word/test"
                    element={
                      <LessonWord
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="test"
                      />
                    }
                  />
                  <Route
                    path="/lesson/sentence"
                    element={
                      <LessonSentence
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="learn"
                      />
                    }
                  />
                  <Route
                    path="/lesson/sentence/audio"
                    element={
                      <LessonSentence
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="audio"
                      />
                    }
                  />
                  <Route
                    path="/lesson/sentence/test"
                    element={
                      <LessonSentence
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="test"
                      />
                    }
                  />
                  <Route
                    path="/lesson/test/"
                    element={
                      <LessonTest
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        windowSize={windowSize}
                        lessonId={lessonId}
                        target="test"
                      />
                    }
                  />
                  <Route
                    path="/lesson/test/text"
                    element={
                      <TestText windowSize={windowSize} lessonId={lessonId} />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <ChooseLesson
                        setLessonId={lessonIdSet}
                        windowSize={windowSize}
                      />
                    }
                  />
                  <Route path="/math" element={<ChooseLevel />} />
                  <Route
                    path="/math/level/1"
                    element={
                      <Level
                        windowSize={windowSize}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        minNumber={0}
                        maxNumber={25}
                        key={1}
                        level={1}
                      />
                    }
                  />
                  <Route
                    path="/math/level/2"
                    element={
                      <Level
                        windowSize={windowSize}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        minNumber={20}
                        maxNumber={50}
                        key={2}
                        level={2}
                      />
                    }
                  />
                  <Route
                    path="/math/level/3"
                    element={
                      <Level
                        windowSize={windowSize}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        minNumber={50}
                        maxNumber={100}
                        key={3}
                        level={3}
                      />
                    }
                  />
                  <Route
                    path="/math/level/4"
                    element={
                      <Level
                        windowSize={windowSize}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        minNumber={100}
                        maxNumber={250}
                        key={4}
                        level={4}
                      />
                    }
                  />
                  <Route
                    path="/math/level/5"
                    element={
                      <Level
                        windowSize={windowSize}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        minNumber={250}
                        maxNumber={500}
                        key={5}
                        level={5}
                      />
                    }
                  />
                  <Route
                    path="/math/level/6"
                    element={
                      <Level
                        windowSize={windowSize}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                        minNumber={500}
                        maxNumber={1000}
                        key={6}
                        level={6}
                      />
                    }
                  />
                  <Route
                    path="/sponsors"
                    element={
                      <Sponsors
                        notificationsSet={notificationsSet}
                        notifications={notifications}
                        windowSize={windowSize}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/page/:id"
                    element={
                      <Sponsors
                        notificationsSet={notificationsSet}
                        notifications={notifications}
                        windowSize={windowSize}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/major"
                    element={
                      <MajorSponsors
                        notificationsSet={notificationsSet}
                        notifications={notifications}
                        windowSize={windowSize}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/major/page/:id"
                    element={
                      <MajorSponsors
                        notificationsSet={notificationsSet}
                        notifications={notifications}
                        windowSize={windowSize}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/major/:id"
                    element={<MajorSponsorDetail windowSize={windowSize} />}
                  />
                  <Route
                    path="/sponsors/ads/"
                    element={
                      <Ads
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/ads/page/:id"
                    element={
                      <Ads
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/"
                    element={
                      <Products
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/services"
                    element={
                      <Services
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/services/:id"
                    element={<ServiceDetail windowSize={windowSize} />}
                  />
                  <Route
                    path="/market/all"
                    element={
                      <Markets
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/mine"
                    element={
                      <MyProducts
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/mine/add"
                    element={
                      <ProductAddForm
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/mine/edit/:code"
                    element={
                      <ProductEditForm
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/mine/services"
                    element={
                      <MyServices
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/mine/services/add"
                    element={
                      <ServiceAddForm
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/mine/service/edit/:id"
                    element={
                      <ServiceEditForm
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/market/feedbacks"
                    element={
                      <Feedbacks
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/admin"
                    element={
                      <MySponsors
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/admin/add"
                    element={
                      <SponsorAddForm
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/sponsors/admin/edit/:id"
                    element={
                      <SponsorEditForm
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/1"
                    element={
                      <Level1
                        key={1}
                        level={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/2"
                    element={
                      <Level1
                        key={2}
                        level={2}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/3"
                    element={
                      <Level1
                        key={3}
                        level={3}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/4"
                    element={
                      <Level1
                        key={4}
                        level={4}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/5"
                    element={
                      <Level1
                        key={5}
                        level={5}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/6"
                    element={
                      <Level1
                        key={6}
                        level={6}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/7"
                    element={
                      <Level1
                        key={7}
                        level={7}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/8"
                    element={
                      <Level1
                        key={8}
                        level={8}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/9"
                    element={
                      <Level1
                        key={9}
                        level={9}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/10"
                    element={
                      <Level1
                        key={10}
                        level={10}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/11"
                    element={
                      <Level1
                        key={11}
                        level={11}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/12"
                    element={
                      <Level1
                        key={12}
                        level={12}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography/13"
                    element={
                      <Level1
                        key={13}
                        level={13}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/geography"
                    element={
                      <ChooseGame
                        key={13}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/games"
                    element={
                      <Games
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry"
                    element={
                      <ChooseChemistryGame
                        key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/1"
                    element={
                      <ChemistryLevel
                        key={1}
                        level={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/2"
                    element={
                      <ChemistryLevel
                        key={2}
                        level={2}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/3"
                    element={
                      <ChemistryLevel
                        key={3}
                        level={3}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/4"
                    element={
                      <ChemistryLevel
                        key={4}
                        level={4}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/5"
                    element={
                      <ChemistryLevel
                        key={5}
                        level={5}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/6"
                    element={
                      <ChemistryLevel
                        key={6}
                        level={6}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/7"
                    element={
                      <ChemistryLevel
                        key={7}
                        level={7}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chemistry/level/8"
                    element={
                      <ChemistryLevel
                        key={8}
                        level={8}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/oauth/login"
                    element={
                      <OauthRedirect
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/blitz"
                    element={
                      <ChooseBlitzTimeout
                        key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/blitz/level/1"
                    element={
                      <Blitz
                        key={1}
                        min={1}
                        max={25}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/blitz/level/2"
                    element={
                      <Blitz
                        key={2}
                        min={1}
                        max={50}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/blitz/level/3"
                    element={
                      <Blitz
                        key={3}
                        min={1}
                        max={100}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/blitz/level/4"
                    element={
                      <Blitz
                        key={4}
                        min={1}
                        max={250}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/operation/1"
                    element={
                      <Operation
                        key={1}
                        min={1}
                        max={25}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/operation/2"
                    element={
                      <Operation
                        key={2}
                        min={1}
                        max={50}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/operation/3"
                    element={
                      <Operation
                        key={3}
                        min={1}
                        max={100}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/operation/4"
                    element={
                      <Operation
                        key={4}
                        min={1}
                        max={250}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/chat/:room"
                    element={
                      <Chat
                        key={1}
                        isAdmin={isAdmin}
                        setIsAdmin={setIsAdmin}
                        chatNotificationList={chatNotificationList}
                        setChatNotificationList={setChatNotificationList}
                        setContactList={setContactList}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/alphabet"
                    element={
                      <ChooseAlphabetLanguage
                        key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/alphabet/2"
                    element={
                      <AlphabetLevel
                        key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/alphabet/3"
                    element={
                      <AlphabetLevel2
                        key={1}
                        level={2}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/alphabet/4"
                    element={
                      <AlphabetLevel2
                        key={2}
                        level={3}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/operation"
                    element={
                      <ChooseOperationTimeout
                        key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/karaoke"
                    element={
                      <ChooseKaraokeLanguagee
                        key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        setKaraokeSidebarItems={setKaraokeSidebarItems}
                      />
                    }
                  />
                  <Route
                    path="/karaoke/:id"
                    element={
                      <Karaoke
                        // key={1}
                        notificationsSet={notificationsSet}
                        windowSize={windowSize}
                        notifications={notifications}
                        karaokeSidebarItems={karaokeSidebarItems}
                        setKaraokeSidebarItems={setKaraokeSidebarItems}
                      />
                    }
                  />
                  <Route path="/progress/top-users" element={<TopUsers />} />
                  <Route path="/progress/:lang" element={<Progress />} />
                  <Route path="/dictionary/choose" element={<ChooseGrade />} />
                  <Route
                    path="/dictionary/chooseLevel/:book_name"
                    element={<ChoosePageRange />}
                  />
                  <Route
                    path="/dictionary/1"
                    element={
                      <DictionaryText
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/dictionary/2"
                    element={
                      <DictionaryLearnWord
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/dictionary/4"
                    element={
                      <DictionaryTestWord
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                  <Route
                    path="/dictionary/3"
                    element={
                      <DictionaryAudioWord
                        windowSize={windowSize}
                        notifications={notifications}
                        inputFocusedSet={inputFocusedSet}
                        inputFocused={inputFocused}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
      {/* </div> */}
    </main>
  );
}

export default App;
