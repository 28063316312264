import { useNavigate } from "react-router-dom";

export default function Banner(props) {
  const navigate = useNavigate();

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  const navigateToService = (e, id) => {
    navigate("/market/services/" + id);
  };

  return (
    <div className="banner-card">
      {props.source === "products" ? (
        <a
          onClick={(e) => {
            navigateToService(e, props.banner.id);
          }}
        >
          <img src={props.banner.image_1}></img>
          <img src={props.banner.image_2}></img>
        </a>
      ) : (
        <a
          onClick={(e) => {
            navigateToSponsor(e, props.banner.id);
          }}
        >
          <img src={props.banner.image}></img>
          <img src={props.banner.image2}></img>
        </a>
      )}
    </div>
  );
}
