import React from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

export default function Card(props) {
  const [opened, setOpened] = React.useState(false);
  const [favorite, setFavorite] = React.useState(props.item.liked);
  const [current, setCurrent] = React.useState(1);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 3
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  const toggleFavorite = () => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };

      axios
        .get(
          "https://peydalan.com.tm/api/add-service-favorite?id=" +
            props.item.id,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setFavorite(response.data.liked);
          props.addToFavorites(props.item.id);
          // Handle the response, such as showing a success message
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setPopupOpened(true);
    }
  };

  return (
    <div className="text-content-frame">
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">
              Halaýanlaryňyza goşmak üçin ilki içeri giriň. Içeri girmek, bu
              posty ýatda saklamaga we soň aňsatlyk bilen görmäge mümkinçilik
              berer. Hasabyňyz ýok bolsa, mugt döredip bilersiňiz. Aşakdaky
              "Giriş" düwmesine basyň. Hasaba gireniňizden soň, postyň
              aşagyndaky ýürek nyşanyna basyp, bu sahypany halanýanlaryňyza
              goşup bilersiňiz.
            </div>
            <div className="actions">
              <button
                className="button button-login"
                onClick={() => {
                  navigate("/settings");
                }}
              >
                {" "}
                Giriş{" "}
              </button>
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                Ýok, Sagboluň
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      {/* <img src={"https://peydalan.com.tm" + props.item.image_1} /> */}
      {!opened && props.item.image_1 && <img src={props.item.image_1} />}
      <div style={{ display: opened ? "block" : "none" }}>
        <div className="slideshow-container">
          {current == 1 && (
            <div>
              <div className="numbertext">1 / 3</div>
              <img src={props.item.image_1} className="slide-img" />
            </div>
          )}
          {current == 2 && (
            <div>
              <div className="numbertext">2 / 3</div>
              <img
                src={props.item.image_2 ? props.item.image_2 : props.item.image}
                className="img"
              />
            </div>
          )}
          {current == 3 && (
            <div>
              <div className="numbertext">3 / 3</div>
              <img
                src={props.item.image_3 ? props.item.image_3 : props.item.image}
                className="img"
              />
            </div>
          )}
          <a
            className="prev"
            onClick={() => {
              change(false);
            }}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => {
              change(true);
            }}
          >
            &#10095;
          </a>
        </div>
      </div>
      <table className="product-table">
        <tr className="product-row">
          <td className="product-title">Kategoriýasy:</td>
          <td className="product-info">
            {props.item.market.category_service.name}
          </td>
        </tr>
        <tr className="product-row">
          <td className="product-title">Bahasy:</td>
          <td className="product-info">{props.item.price}</td>
        </tr>
      </table>
      {opened && <div className="product-about">{props.item.about}</div>}
      {opened && (
        <div className="product-market-info">
          {props.item.market.name}
          {"  "}
          {props.item.market.phone_number}
          {"  "}({props.item.market.code})
        </div>
      )}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
      <button className="text-button favorite-button" onClick={toggleFavorite}>
        <i className="material-icons" id="favorite-i">
          {favorite ? "favorite" : "favorite_border"}
        </i>
      </button>
    </div>
    // _LjTrr4LyU!ksAh
  );
}
