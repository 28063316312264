import { useNavigate } from "react-router-dom";
import React from "react";
import { OperationCard, OperationTestCard } from "../cards/OperationCard";
import axios from "axios";

const getRandomOperation = () => {
  const operations = ["+", "-", "*", "/"];
  const randomIndex = Math.floor(Math.random() * operations.length);
  return operations[randomIndex];
};

export function Operation(props) {
  const [dialog, setDialog] = React.useState([{}]);
  const [timeSelected, setTimeSelected] = React.useState(
    localStorage.getItem("operation")
  );

  const [isTyping, setIsTyping] = React.useState(false);

  const [ad, setAd] = React.useState();
  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const correctAnswer = React.useRef();
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer
  const [selected, setSelected] = React.useState();

  const counter = React.useRef(0);
  const statistics = React.useRef({ corrects: 0, errors: 0 });

  const input = React.useRef();
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const welcomeMessage = React.useRef("");
  const selectedNumber1 = React.useRef();
  const selectedNumber2 = React.useRef();
  const chosen_operation = React.useRef();

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };
  const playCorrectSound = () => {
    audioObject.current.play().catch((error) => {
      // handle error
      console.error(error);
    });
  };

  const playWrongSound = () => {
    audioObject2.current.play().catch((error) => {
      // handle error
      console.error(error);
    });
  };
  const sendError = (text, lean) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: lean
          ? "☹️ \n Ýetişmediňiz!!! Dogrusy:  " + text
          : "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
        button: lean,
        callback: sendQuestion,
      },
    ]);
  };

  const sendInfo = (message) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: message,
        me: false,
        info: true,
      },
    ]);
  };

  const getRandomNumber = (minNumber, maxNumber) => {
    const randomNumber =
      Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
    return randomNumber;
  };

  const sendQuestion = (first = false) => {
    var number1 = getRandomNumber(props.min, props.max);
    var number2 = getRandomNumber(props.min, props.max);

    var operation = getRandomOperation();

    if (operation == "/") {
      while (
        (number1 % number2 !== 0) |
        (number1 === 0) |
        (number2 === 1) |
        (number1 === number2)
      ) {
        var number1 = getRandomNumber(1, props.max);
        var number2 = getRandomNumber(1, props.max);
      }
    }
    if (operation == "+") {
      while (
        (number1 + number2 > props.max) |
        (number1 + number2 < props.min) |
        (number1 === 0) |
        (number2 === 0)
      ) {
        var number1 = getRandomNumber(0, props.max);
        var number2 = getRandomNumber(0, props.max);
      }
    }
    if (operation == "*") {
      while (
        (number1 * number2 > props.max) |
        (number1 === 0) |
        (number2 === 0) |
        (number1 === 1) |
        (number2 === 1)
      ) {
        var number1 = getRandomNumber(1, props.max);
        var number2 = getRandomNumber(1, props.max);
      }
    }
    if (operation == "-") {
      while ((number1 - number2 < 0) | (number1 === 0) | (number2 === 0)) {
        var number1 = getRandomNumber(1, props.max);
        var number2 = getRandomNumber(1, props.max);
      }
    }
    setIsTyping(false);
    // get the question at that index
    if (operation == "+") {
      correctAnswer.current = number1 + number2; // add the question to the dialog
    } else if (operation == "-") {
      correctAnswer.current = number1 - number2; // add the question to the dialog
    } else if (operation == "*") {
      correctAnswer.current = number1 * number2; // add the question to the dialog
    } else if (operation == "/") {
      correctAnswer.current = number1 / number2; // add the question to the dialog
    }
    setDialog((dialogs) => [
      ...dialogs,
      {
        message:
          number1.toString() +
          " ? " +
          number2.toString() +
          " = " +
          correctAnswer.current,
        me: false,
        question: true,
        answer:
          number1.toString() +
          " " +
          operation +
          number2.toString() +
          " " +
          " = " +
          correctAnswer.current,
        first: first,
      },
    ]);
    selectedNumber1.current = number1;
    selectedNumber2.current = number2;
    chosen_operation.current = operation;
  };

  React.useEffect(() => {
    var answer;
    if (selected) {
      if (selected == "+") {
        if (
          selectedNumber1.current + selectedNumber2.current ==
          correctAnswer.current
        ) {
          setIsTyping(true);
          statistics.current = {
            ...statistics.current,
            corrects: statistics.current.corrects + 1,
          };
          setTimeout(() => {
            sendInfo("👍 Dogry.");
            playCorrectSound();
          }, 1000);
          setTimeout(() => {
            sendQuestion();
          }, 2000);
          setSelected(false);
          return;
        } else {
          answer = "+";
        }
      } else if (selected == "-") {
        if (
          selectedNumber1.current - selectedNumber2.current ==
          correctAnswer.current
        ) {
          setIsTyping(true);
          statistics.current = {
            ...statistics.current,
            corrects: statistics.current.corrects + 1,
          };
          setTimeout(() => {
            sendInfo("👍 Dogry.");
            playCorrectSound();
          }, 1000);
          setTimeout(() => {
            sendQuestion();
          }, 2000);
          setSelected(false);
          return;
        } else {
          answer = "-";
        }
      } else if (selected == "*") {
        if (
          selectedNumber1.current * selectedNumber2.current ==
          correctAnswer.current
        ) {
          setIsTyping(true);
          statistics.current = {
            ...statistics.current,
            corrects: statistics.current.corrects + 1,
          };
          setTimeout(() => {
            sendInfo("👍 Dogry.");
            playCorrectSound();
          }, 1000);
          setTimeout(() => {
            sendQuestion();
          }, 2000);
          setSelected(false);
          return;
        } else {
          answer = "*";
        }
      } else if (selected == "/") {
        if (
          selectedNumber1.current / selectedNumber2.current ==
          correctAnswer.current
        ) {
          setIsTyping(true);
          statistics.current = {
            ...statistics.current,
            corrects: statistics.current.corrects + 1,
          };
          setTimeout(() => {
            sendInfo("👍 Dogry.");
            playCorrectSound();
          }, 1000);
          setTimeout(() => {
            sendQuestion();
          }, 2000);
          setSelected(false);
          return;
        } else {
          answer = "/";
        }
      } else if (selected == "not-selected") {
        setTimeout(() => {
          statistics.current = {
            ...statistics.current,
            errors: statistics.current.errors + 1,
          };
          sendError(chosen_operation.current, true);
          playWrongSound();
        }, 1000);
        setSelected(false);
        return;
      }
      setTimeout(() => {
        statistics.current = {
          ...statistics.current,
          errors: statistics.current.errors + 1,
        };
        sendError(answer);
        playWrongSound();
      }, 1000);
      setTimeout(() => {
        sendQuestion();
      }, 2000);
      setSelected(false);
    }
  }, [selected]);

  React.useEffect(() => {
    //   var operation_verbose;
    //   var operation = localStorage.getItem("math");
    //   if (operation == "-") {
    //     operation_verbose = "aýyrmak";
    //   } else if (operation == "+") {
    //     operation_verbose = "goşmak";
    //   } else if (operation == "*") {
    //     operation_verbose = "köpeltmek";
    //   } else if (operation == "/") {
    //     operation_verbose = "bölmek";
    //   } else if (operation == "*/") {
    //     operation_verbose = "köpeltmek we bölmek";
    //   } else if (operation == "+-") {
    //     operation_verbose = "goşmak we aýyrmak";
    //   }

    //   if (props.level == 1) {
    //     welcomeMessage.current =
    //       "Salam! Matematika öwrenmegiň 1-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 0-dan 25-e çenli sanlary " +
    //       operation_verbose +
    //       " arkaly türgenleşik geçeris. Üstünlik!";
    //   } else if (props.level == 2) {
    //     welcomeMessage.current =
    //       "Salam! Matematika öwrenmegiň 2-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 25-den 50-ä çenli sanlary " +
    //       operation_verbose +
    //       " arkaly türgenleşik geçeris. Üstünlik!";
    //   } else if (props.level == 3) {
    //     welcomeMessage.current =
    //       "Salam! Matematika öwrenmegiň 3-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 50-den 100-e çenli sanlary " +
    //       operation_verbose +
    //       " arkaly türgenleşik geçeris. Üstünlik!";
    //   } else if (props.level == 4) {
    //     welcomeMessage.current =
    //       "Salam! Matematika öwrenmegiň 4-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 100-den 250-e çenli sanlary " +
    //       operation_verbose +
    //       " arkaly türgenleşik geçeris. Üstünlik!";
    //   } else if (props.level == 5) {
    //     welcomeMessage.current =
    //       "Salam! Matematika öwrenmegiň 5-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 250-den 500-e çenli sanlary " +
    //       operation_verbose +
    //       " arkaly türgenleşik geçeris. Üstünlik!";
    //   } else if (props.level == 6) {
    //     welcomeMessage.current =
    //       "Salam! Matematika öwrenmegiň 6-njy ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz 500-den 1000-e çenli sanlary " +
    //       operation_verbose +
    //       " arkaly türgenleşik geçeris. Üstünlik!";
    //   }

    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (ad) {
      setTimeout(() => {
        setDialog((dialog) => [
          {
            message: ad.image,
            me: false,
            ad: true,
            view: ad.view,
            id: ad.id,
          },
          ...dialog.slice(1),
        ]);
      }, 500);
    }
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              !item.selectTime ? (
                <OperationCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  setInputFocus={setInputFocus}
                  sponsorId={item.id}
                  message={item.message}
                  question={item.question}
                  setSelected={setSelected}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                  delayInSeconds={timeSelected}
                  button={item.button}
                  callback={item.callback}
                  answer={item.answer}
                  first={item.first}
                />
              ) : (
                <OperationTestCard
                  key={index}
                  callback={item.callback}
                  selected={timeSelected}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Doňdurylan..."
                value={message}
                ref={input}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                disabled
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button type="button" className="btn send">
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChooseOperationTimeout() {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("operation", button_name);
    navigate("/operation/1");
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Mysalyn amallaryny tapmak</h4>
      <button
        className="test-button"
        onClick={() => {
          handleClick("1");
        }}
      >
        1 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("2");
        }}
      >
        2 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("3");
        }}
      >
        3 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("4");
        }}
      >
        4 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("5");
        }}
      >
        5 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("6");
        }}
      >
        6 sekunt
      </button>
      <br />
      <h5>Düşündiriş teksti</h5>
      <p></p>
    </div>
  );
}
