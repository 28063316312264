import React from "react";
import { useNavigate } from "react-router-dom";

export default function TextCard(props) {
  const navigate = useNavigate();
  const handleButton = () => {
    navigate("/lesson/word");
  };
  return (
    <div className="text-content-frame">
      <img src={"https://peydalan.com.tm" + props.item.image_another}></img>
      <button
        className="text-button text-button-top"
        onClick={() => {
          handleButton();
        }}
      >
        Sapaga geç
      </button>
      <p>{props.item.another_lang} </p> <br />
      <p>{props.item.turkmen}</p>
    </div>
  );
}
