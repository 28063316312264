import React from "react";
import axios from "axios";
import MessageCard from "../cards/KaraokeMessageCard";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { useNavigate, useParams } from "react-router-dom";

export function Karaoke(props) {
  const [ad, setAd] = React.useState([]);
  const [dialog, setDialog] = React.useState([{}]);
  const [song, setSong] = React.useState({});
  const [options, setOptions] = React.useState([
    { label: "Original", value: "original" },
    { label: "Karaoke", value: "karaoke" },
    { label: "Pause", value: "pause" },
    { label: "Play", value: "play" },
  ]);
  const [isTyping, setIsTyping] = React.useState(false);
  const [selectedCommand, setSelectedCommand] = React.useState();
  const messagesEndRef = React.useRef(null);
  const welcomeMessage = React.useRef("");
  const audioObject = React.useRef();
  const input = React.useRef();
  const lyricIndex = React.useRef(-1);
  const sentSecond = React.useRef([]);
  const { id } = useParams();

  React.useEffect(() => {
    var headers = {};
    if (localStorage.getItem("token")) {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
    }
    setDialog([{}]);
    var url = "https://peydalan.com.tm/api/lyrics/songs?id=" + id;
    axios
      .get(url, { headers: headers })
      .then((response) => {
        // set the array state to the response data
        setSong(response.data);
        welcomeMessage.current = response.data.name;
        console.log(response.data);
        setTimeout(() => {
          setDialog((dialogs) => [
            ...dialogs,
            {
              message: welcomeMessage.current,
              me: false,
            },
          ]);
        }, 2000);
      })
      .catch((error) => {
        // handle error
        alert("Song not found");
      });
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [id]);

  React.useEffect(() => {
    console.log(props.karaokeSidebarItems);
    if (props.karaokeSidebarItems.length < 1 && song) {
      axios
        .get("https://peydalan.com.tm/api/lyrics/songs?lang=" + song.category)
        .then((res) => {
          props.setKaraokeSidebarItems(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [song]);

  const sendMessage = () => {
    var message = selectedCommand.value;
    setDialog((dialog) => [...dialog, { message: message, me: true }]);
    setSelectedCommand({});

    setIsTyping(true);

    if (message === "karaoke" || message === "original") {
      sentSecond.current = [];
      lyricIndex.current = -1;
      var interval = setInterval(() => {
        if (lyricIndex.current < 1) {
          lyricIndex.current += 1;
          setDialog((dialog) => [
            ...dialog,
            {
              message: song.lyrics[lyricIndex.current].body,
              time: song.lyrics[lyricIndex.current].time,
              id: song.lyrics[lyricIndex.current].id,
            },
          ]);
          sentSecond.current.push(song.lyrics[lyricIndex.current].time);
        } else if (lyricIndex.current === 1) {
          audioObject.current.play();
          clearInterval(interval);
        }
      }, 1500);
    } else if (message === "play") {
      setIsTyping(true);
      audioObject.current.play();
    } else if (message === "pause") {
      setIsTyping(false);
      audioObject.current.pause();
    }
  };

  React.useEffect(() => {
    if (selectedCommand && selectedCommand.value) {
      var command = selectedCommand.value;
      if (command === "original") {
        audioObject.current.src = song.original;
        audioObject.current.load();
      } else if (command === "karaoke") {
        audioObject.current.src = song.karaoke;
        audioObject.current.load();
      } else if (command === "pause" || command === "play") {
        sendMessage();
      }
    }
  }, [selectedCommand]);

  const handleTimeUpdate = (e) => {
    var currentSecond = Math.floor(e.currentTarget.currentTime);

    if (currentSecond > 0) {
      var addCurrent;
      var lyrics = song.lyrics.filter((item, index) => {
        if (addCurrent) {
          addCurrent = false;
          if (sentSecond.current.indexOf(item.time) === -1) {
            sentSecond.current.push(item.time);
            return true;
          }
        }
        if (item.time > currentSecond - 2 && item.time < currentSecond) {
          addCurrent = true;
        }
      });
      var formattedLyric = [];
      lyrics.map((item, index) => {
        formattedLyric.push({
          message: item.body,
          time: item.time,
          id: item.id,
        });
      });

      var newArray = [...dialog]; // Create a copy of the array
      newArray = newArray.map((item, index) => {
        // if (index > 1 && !item.me) {
        //   return { ...item, active: true };
        // }
        if (item.time > currentSecond - 2 && item.time < currentSecond) {
          return { ...item, active: true };
        }
        return item;
      });
      // newArray[newArray.length - 1].active = true; // Modify the second-to-last element
      setDialog([...newArray, ...formattedLyric]);
    }
  };

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          onTouchEnd: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
      >
        <i
          onMouseDown={() => sendMessage()}
          onTouchEnd={() => sendMessage()}
          className="material-icons"
        >
          send
        </i>
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src={song.original}
            onTimeUpdate={handleTimeUpdate}
            onEnded={() => {
              setIsTyping(false);
            }}
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                ad={item.ad}
                view={item.view}
                sponsorId={item.id}
                message={item.message}
                id={item.id}
                time={item.time}
                me={item.me && "true"}
                active={item.active}
                canChangeLyricTime={song.canChangeLyricTime}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <Select
                defaultMenuIsOpen
                components={{ DropdownIndicator }}
                closeMenuOnSelect={false}
                options={options}
                ref={input}
                classNamePrefix="react-select"
                hideSelectedOptions={false}
                menuIsOpen={true}
                onChange={(e) => {
                  setSelectedCommand(e);
                }}
                value={selectedCommand}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChooseKaraokeLanguagee({ setKaraokeSidebarItems }) {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    axios
      .get("https://peydalan.com.tm/api/lyrics/songs?lang=" + button_name)
      .then((res) => {
        setKaraokeSidebarItems(res.data);
        navigate("/karaoke/" + res.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Karaoke</h4>
      <button
        className="test-button"
        onClick={() => {
          handleClick("tk");
        }}
      >
        Türkmençe aýdymlar
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("en");
        }}
      >
        Iňlisçe aýdymlar
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("ru");
        }}
      >
        Rusça aýdymlar
      </button>
      <br />
      <h5>Düşündiriş teksti</h5>
      <p></p>
    </div>
  );
}
