import React, { useEffect, useRef, useState, useTransition } from "react";
import wavesurferMin from "wavesurfer";

export default function AudioMessage({ audio, setInputFocus }) {
  const waveformRef = useRef(null);
  const [wave, setWave] = useState();

  useEffect(() => {
    setWave(
      wavesurferMin.create({
        container: waveformRef.current,
        waveColor: "#f79902",
        progressColor: "green",
        height: 50,
        barWidth: 3,
        barHeight: 1,
        barGap: null,
        responsive: true,
        normalize: true,
        backend: "WebAudio",
      })
    );
  }, []);
  // Create a new WaveSurfer instance
  useEffect(() => {
    if (!wave) {
      return;
    }

    wave.load(audio);
    setTimeout(() => {
      wave.play();
    }, 500);
  }, [wave]);
  // Load and play the audio file

  const playAudio = () => {
    setInputFocus();
    wave.play();
  };

  return (
    <div className="message" onClick={playAudio}>
      <div className="text-main">
        <div className="text-group">
          <div className="text">
            <div id="waveform" ref={waveformRef} style={{ width: "300px" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
