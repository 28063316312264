import axios from "axios";
import React from "react";

import LearnSentence from "./lessons/LearnSentence";
import AudioSentence from "./lessons/AudioSentence";
import TestSentence from "./lessons/TestSentence";

export default function LessonSentence(props) {
  const [lesson, setLesson] = React.useState();
  const [ad, setAd] = React.useState([]);
  const [dialogs, setDialogs] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/lesson?id=" + props.lessonId)
      .then((response) => {
        // set the array state to the response data
        setLesson(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [props.lessonId]);

  React.useEffect(() => {
    // if (!lesson) {
    //   return;
    // }
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    axios
      .get("https://peydalan.com.tm/api/dialog?lesson=" + props.lessonId)
      .then((response) => {
        // set the array state to the response data
        setDialogs(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [lesson]);

  const refreshDialogs = () => {
    axios
      .get("https://peydalan.com.tm/api/dialog?lesson=" + props.lessonId)
      .then((response) => {
        // set the array state to the response data
        setDialogs(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  React.useEffect(() => {
    localStorage.setItem("lesson", props.lessonId);
  }, [props.lessonId]);

  if (props.target === "learn") {
    return (
      <LearnSentence
        dialogs={dialogs}
        ad={ad}
        lesson={lesson}
        windowSize={props.windowSize}
        inputFocusedSet={props.inputFocusedSet}
        inputFocused={props.inputFocused}
        refreshDialogs={() => {
          refreshDialogs();
        }}
      />
    );
  } else if (props.target === "audio") {
    return (
      <AudioSentence
        dialogs={dialogs}
        lesson={lesson}
        ad={ad}
        windowSize={props.windowSize}
        inputFocusedSet={props.inputFocusedSet}
        inputFocused={props.inputFocused}
        refreshDialogs={() => {
          refreshDialogs();
        }}
      />
    );
  } else if (props.target === "test") {
    return (
      <TestSentence
        dialogs={dialogs}
        ad={ad}
        lesson={lesson}
        windowSize={props.windowSize}
        inputFocusedSet={props.inputFocusedSet}
        inputFocused={props.inputFocused}
      />
    );
  }
}
