import React from "react";
import axios from "axios";
import MessageCard from "../cards/GeographyMessageCard";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GeographyTestCard from "../cards/GeographyTestCard";

export function Level1(props) {
  const [ad, setAd] = React.useState([]);
  const [dialog, setDialog] = React.useState([{}]);
  const [selected, setSelected] = React.useState();
  const countries = React.useRef();
  const countries_backup = React.useRef();
  const [isTyping, setIsTyping] = React.useState(false);
  const [correctAnswer, setCorrectAnswer] = React.useState();
  const messagesEndRef = React.useRef(null);
  const welcomeMessage = React.useRef("");
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const statistics = React.useRef({ corrects: 0, errors: 0 });
  const timeout = React.useRef(0);
  const gameMode = React.useRef(localStorage.getItem("geo")).current;

  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ " + text,
        me: false,
        error: true,
      },
    ]);
    setTimeout(() => {
      sendInfo(true);
    }, 500);
  };

  const sendInfo = (not = null) => {
    var text = `Ady: ${correctAnswer.name}
Paýtagty: ${correctAnswer.capital}
Ilaty (takmynan): ${correctAnswer.population}
Meýdany: ${correctAnswer.area} km²,
Serhetleşýän ýurtlary: ${correctAnswer.neighbouring_countries}
`;
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: text,
        me: false,
        info: true,
        map: correctAnswer.orth_map,
      },
    ]);
    if (not == null) {
      setTimeout(() => {
        sendQuestion();
      }, 3000);
    }
  };

  const sendStatistics = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: text,
        me: false,
        info: true,
      },
    ]);
  };

  React.useEffect(() => {
    var url;
    const level = props.level;
    if (level == undefined) {
      url = "https://peydalan.com.tm/api/countries/get?level=1";
    } else {
      url = "https://peydalan.com.tm/api/countries/get?level=" + level;
    }
    axios
      .get(url)
      .then((response) => {
        // set the array state to the response data
        countries.current = response.data;
        countries_backup.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const selectedSet = (text) => {
    setSelected(text);
  };

  function getRandomAnswers(answer) {
    // Create a copy of the state array
    let copy = [...countries_backup.current];
    // Remove the given element from the copy
    let index = copy.indexOf(answer);
    if (index > -1) {
      copy.splice(index, 1);
    }
    // Shuffle the copy array
    for (let i = copy.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [copy[i], copy[j]] = [copy[j], copy[i]];
    }
    // Return the first two elements of the copy array
    return copy.slice(0, 3);
  }

  const getRandomObject = (array) => {
    const randomObject = array[Math.floor(Math.random() * array.length)];
    return randomObject;
  };

  const sendQuestion = () => {
    if (countries) {
      const question = getRandomObject(countries.current);
      var falseAnswers = getRandomAnswers(question);
      falseAnswers = [...falseAnswers, question];

      const answers = shuffleArray(falseAnswers);

      setCorrectAnswer(question);
      setIsTyping(false);
      // get the question at that index
      if (gameMode == "1") {
        setDialog((dialogs) => [
          ...dialogs,
          {
            question:
              "[EN] " + question.name + "\n[TM] " + question.name_turkmen,
            image1: answers[0].flag,
            image2: answers[1].flag,
            image3: answers[2].flag,
            image4: answers[3].flag,
            true: question.flag,
            audio: question.name_audio,
            mode: "1",
            me: false,
          },
        ]);
      } else {
        setDialog((dialogs) => [
          ...dialogs,
          {
            question: question.flag,
            answer1: answers[0],
            answer2: answers[1],
            answer3: answers[2],
            answer4: answers[3],
            true: question.name,
            mode: "2",
            me: false,
          },
        ]);
      }
    }
  };

  const deleteItem = (name) => {
    countries.current = countries.current.filter((item) => item.name !== name);
  };

  const getItem = (flag) => {
    return countries_backup.current.find((item) => item.flag === flag);
  };

  React.useEffect(() => {
    if (!selected) {
      return;
    }

    var c_answer;
    if (gameMode === "1") {
      c_answer = correctAnswer.flag;
    } else {
      c_answer = correctAnswer.name;
    }

    // Then use the setState method to update the state with the new array
    if (selected === c_answer) {
      playCorrectSound();

      statistics.current = {
        ...statistics.current,
        corrects: statistics.current.corrects + 1,
      };

      setTimeout(() => {
        sendInfo();
      }, 1000);
    } else {
      playWrongSound();

      statistics.current = {
        ...statistics.current,
        errors: statistics.current.errors + 1,
      };
      var selected_country = getItem(selected);
      setTimeout(() => {
        var errorMessage;
        if (gameMode === "1") {
          errorMessage =
            "Siz ýalnyşyp " +
            selected_country.name +
            " ýurduňkyny saýladyňyz, dogrysy ýokarda görkezilen";
        } else {
          errorMessage =
            "Ýalňyş jogap saýladyňyz. Dogrysy: " + correctAnswer.name;
        }
        sendError(errorMessage);
      }, 1000);
      setTimeout(() => {
        sendQuestion();
      }, 2500);
    }
    deleteItem(correctAnswer.name);
    setSelected(null);
    if (countries.current.length == 0) {
      countries.current = countries_backup.current;

      sendStatistics(
        countries_backup.current.length +
          " soragdan \n Dogrysy: " +
          statistics.current.corrects +
          " \n Ýalňyşy: " +
          statistics.current.errors
      );
      statistics.current = { corrects: 0, errors: 0 };
    }
  }, [selected]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    welcomeMessage.current =
      "Salam! Dünýä ýurtlarynyň atlaryny, baýdaklaryny we ýerleşýän ýerlerini öwrenmek türgenleşiginiň 1-nji ädimine hoş geldiňiz. Bu sapakda ulylygy boýunça 1-20 aralykdaky ýurtlar görkeziler. Ulanylyş usuly size bir ýurduň ady geler we dört ýurduň baýdagynyň suraty geler, siz şol baçdaklardan birini saýlaýarsyňyz, soňra netije görkezilende şol ýurt barada maglumat görkeziler we ýene täzeden bu ýagdaý gaýtalanar.";
  }, []);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.question ? (
                <GeographyTestCard
                  key={index}
                  question={item.question}
                  image1={item.image1}
                  image2={item.image2}
                  image3={item.image3}
                  image4={item.image4}
                  answer1={item.answer1}
                  answer2={item.answer2}
                  answer3={item.answer3}
                  answer4={item.answer4}
                  true={item.true}
                  mode={item.mode}
                  audio={item.audio}
                  selectedSet={selectedSet}
                />
              ) : (
                <MessageCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                  map={item.map}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                disabled
                className="form-control"
                placeholder="Doňdurylan..."
                rows="0"
              ></textarea>
              <button type="submit" className="btn send">
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChooseGame() {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("geo", button_name);
    navigate("/geography/1");
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Geografiýa</h4>
      <button
        className="test-button"
        onClick={() => {
          handleClick("1");
        }}
      >
        Ýurduň adyna görä baýdagyny tanamak
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("2");
        }}
      >
        Baýdaga göra ýurduň adyny bilmek
      </button>
      <br />
      <h5>Düşündiriş teksti</h5>
      <p>
        Dünýä ýurtlarynyň baýdaklaryny we olaryň giňişleýin maglumatlaryny
        öwrenmek üçin türgenleşik sahypasy
      </p>
    </div>
  );
}
