import React from "react";

export default function AboutWebsite() {
  return (
    <div className="chat" id="chat1">
      <div className="container container-main about-container">
        <h4>peydalan.com.tm web saytymyzyň maksatnamasy:</h4>
        <ol>
          <li>Sanly ulgamy ösdürmäge abraýly goşant goşmagyny gazanmak.</li>
          <li>
            Daşary yurt dillerini, okuw derslerini öwrenmek isleýän
            ildeşlerimize gyzykly we ýeňil ýollar arkaly öwrenmäge türgenleşik
            oýuny.
          </li>
          <li>
            Türgenleşikde ýa-da beýleki hyzmatlardan arakesme edip dynç almak
            üçin arakesme degişmeleri we peýdaly maslahatlar.
          </li>
          <li>
            Biziň toparymyzyň işinden peýda ya-da ruhlanma tapyp, bize ýakyndan
            hemaýat edýänleriň sanawyny görkezmek, we mahabat hyzmatlary.
          </li>
          <li>Telekeçileriň alyp barýan işleriniň mahabat hyzmaty.</li>
          <li>
            peydalan.com.tm web saýtymyza bellige (agza) alynan ulanyjymyza
            şahsy otag hyzmaty.
          </li>
        </ol>
        <h4>peydalan.com.tm web saýtymyzyň dolandyrylyşy:</h4>
        <p>
          Web saýtymyzyň hyzmatlarynyň sahypasy kompýuterde ekranyňyzyň çep
          tarapynda dikligine ýerleşdirilen şekiljikler arkaly ulanylýar.
          Ekranyň çep tarapyndaky dikligine ikinji setirdäki hatly öýjükler
          saýlanylan her sahypamyzyň kömekçi menýusy bolup hyzmat edýär, mysal
          üçin sözlük öwrenmek hyzmatymyzy saýlasaňyz, şol hyzmatymyzy dilini
          çalyşmak we saýlan dilimiziň okadyş ädimlerini çalyşmak üçin
          ulanylýar. Eger siz web saýtymyzy öýjükli telefonyňyz arkaly ulanmakçy
          bolsaňyz, saýtymyzyň esasy sahypalary bolan şekilli öýjüklerimiz
          telefonyňyzyň aşak tarapynda ýerleşdirilendir, her saýlanylan
          sahypanyň kömekçi menýu düwmelerini bolsa ekranyňyzyň ýokarsynda çep
          tarapda üç çyzyk şekilinde goýulan düwme arkaly saýlap bilersiňiz.
          Eger web saýtymyzyň hyzmatlarynyň ulanylyşy barada bilmek isleseňiz{" "}
          <i className="material-icons about-icons">info_outline</i> şekilli
          maglumat sahypamyzdan okap bilersiňiz, ol ýerde her bir hyzmatymyz
          barada gerek bolan maglumatlar ýerleşdirilen. Ýene üns bermeli ýerimiz{" "}
          <i className="material-icons about-icons">settings</i> şekilli
          sazlamalar sahypasyndan bize agza bolsaňyz biziň hyzmatlarymyzdan
          peýdalanýan döwrüňizde size ýeňillikler dörediler, mysal üçin agza
          bolanyňyzdan soň özüňize şahsy otag açylar we halan sahypaňyzy we
          öwrenýän okuw sapaklaryňyzy öz şahsy otagyňyzda saklap bilersiňiz. Bu
          Profilim sahypamyzda biz bilen habarlaşmak üçin aragatnaşyk
          belgilerimiz ýerleşdirilendir. Saýtymyzyň ulanyş düzgünlerini wideo
          ýazgylar arkaly hem tomaşa etmek mümkin. Wideo ýazgylarymyz
          ýerleşdirilen köpçülikleýin platformalarymyzyň link adresleri:
        </p>
      </div>
    </div>
  );
}
