export default function Badge({ count, place }) {
  return (
    <div
      className={
        place == "sidebar"
          ? "notification-container notification-container-sidebar"
          : place == "menu"
          ? "notification-container notification-container-menu"
          : "notification-container"
      }
    >
      <span
        className={
          place == "sidebar"
            ? "notification-badge notification-badge-sidebar"
            : place == "menu"
            ? "notification-badge notification-badge-menu"
            : "notification-badge"
        }
      >
        {count}
      </span>
    </div>
  );
}
