import { useNavigate } from "react-router-dom";

export function Games() {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    navigate("/" + button_name);
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Okuw Oýunlary</h4>

      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M160-391h45l23-66h104l24 66h44l-97-258h-46l-97 258Zm81-103 38-107h2l38 107h-78Zm319-70v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-600q-38 0-73 9.5T560-564Zm0 220v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-380q-38 0-73 9t-67 27Zm0-110v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-490q-38 0-73 9.5T560-454ZM260-320q47 0 91.5 10.5T440-278v-394q-41-24-87-36t-93-12q-36 0-71.5 7T120-692v396q35-12 69.5-18t70.5-6Zm260 42q44-21 88.5-31.5T700-320q36 0 70.5 6t69.5 18v-396q-33-14-68.5-21t-71.5-7q-47 0-93 12t-87 36v394Zm-40 118q-48-38-104-59t-116-21q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740q51-30 106.5-45T700-800q52 0 102 12t96 36q11 5 16.5 15t5.5 21v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-60 0-116 21t-104 59ZM280-499Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("dictionary/choose");
          }}
        >
          Sözlük
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q19 0 37 2t36 5v223q-12-41-30.5-77.5T480-798q-26 36-45 75t-31 83h149v80H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560h80q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560h82q4 20 6 40t2 40q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm206 222q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55ZM204-640h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Zm276 478q26-36 45-75t31-83H404q12 44 31 83t45 75Zm104-16q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178Zm56-462v-240h240v240H640Zm120-120h80v-80h-80v80Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("geography");
          }}
        >
          Geografiýa
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              d="M200-120q-51 0-72.5-45.5T138-250l222-270v-240h-40q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760h-40v240l222 270q32 39 10.5 84.5T760-120H200Zm80-120h400L544-400H416L280-240Zm-80 40h560L520-492v-268h-80v268L200-200Zm280-280Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("chemistry");
          }}
        >
          Himiýa
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("math");
          }}
        >
          Matematika
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("blitz");
          }}
        >
          Yzygider Hasap
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("operation");
          }}
        >
          Mysalyň amallaryny tapmak
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M40-40v-240h80v-400H40v-240h240v80h400v-80h240v240h-80v400h80v240H680v-80H280v80H40Zm240-160h400v-80h80v-400h-80v-80H280v80h-80v400h80v80Zm32-120 136-360h64l136 360h-62l-32-92H408l-32 92h-64Zm114-144h108l-52-150h-4l-52 150ZM120-760h80v-80h-80v80Zm640 0h80v-80h-80v80Zm0 640h80v-80h-80v80Zm-640 0h80v-80h-80v80Zm80-640Zm560 0Zm0 560Zm-560 0Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("alphabet");
          }}
        >
          Harplyk
        </button>
      </div>
      <div className="games-list">
        <i className="material-icons calculate">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
            <path
              fill="#7a797c "
              className="material-icons calculate"
              d="M560-160q-66 0-113-47t-47-113q0-66 47-113t113-47q23 0 42.5 5.5T640-458v-342h240v120H720v360q0 66-47 113t-113 47ZM80-320q0-99 38-186.5T221-659q65-65 152.5-103T560-800v80q-82 0-155 31.5t-127.5 86q-54.5 54.5-86 127T160-320H80Zm160 0q0-66 25.5-124.5t69-102Q378-590 436-615t124-25v80q-100 0-170 70t-70 170h-80Z"
            />
          </svg>
        </i>
        <button
          className="test-button"
          onClick={() => {
            handleClick("karaoke");
          }}
        >
          Karaoke
        </button>
      </div>
    </div>
  );
}
