import React from "react";
import { Link } from "react-router-dom";

export default function GeographySidebar(props) {
  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Sazlamalar Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "choose");
            }}
            to="/geography"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "choose" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Sapak saýla</h5>
              <p>Sapak saýla</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "choose");
            }}
            to="/geography"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "choose" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 1</h5>
              <p>Ýurtlaryň meýdany boýunça 1den 20ä</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-2");
            }}
            to="/geography/2"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-1" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 2</h5>
              <p>Ýurtlaryň meýdany boýunça 20den 40a</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-3");
            }}
            to="/geography/3"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-3" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 3</h5>
              <p>Ýurtlaryň meýdany boýunça 40dan 60a</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-4");
            }}
            to="/geography/4"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-4" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 4</h5>
              <p>Ýurtlaryň meýdany boýunça 60dan 80e</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-5");
            }}
            to="/geography/5"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-5" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 5</h5>
              <p>Ýurtlaryň meýdany boýunça 80den 100e</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-6");
            }}
            to="/geography/6"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-6" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 6</h5>
              <p>Ýurtlaryň meýdany boýunça 100den 120e</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-7");
            }}
            to="/geography/7"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-7" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 7</h5>
              <p>Ýurtlaryň meýdany boýunça 120den 140a</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-8");
            }}
            to="/geography/8"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-8" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 8</h5>
              <p>Ýurtlaryň meýdany boýunça 140dan 160a</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-9");
            }}
            to="/geography/9"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-9" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 9</h5>
              <p>Ýurtlaryň meýdany boýunça 160dan 180e</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "level-10");
            }}
            to="/geography/10"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-10" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 10</h5>
              <p>Ýurtlaryň meýdany boýunça 180den 200e</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-11");
            }}
            to="/geography/11"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-11" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 11</h5>
              <p>Ýurtlaryň meýdany boýunça 200den 220ä</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-12");
            }}
            to="/geography/12"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-12" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 12</h5>
              <p>Ýurtlaryň meýdany boýunça 220den 240a</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "level-13");
            }}
            to="/geography/13"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "level-13" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Level 13</h5>
              <p>Ýurtlaryň meýdany boýunça 240den 250a</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
