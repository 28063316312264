import React from "react";
import { CircleLoader } from "react-spinners";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function Profile() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    axios
      .get("https://peydalan.com.tm/api/get-user", { headers: headers })
      .then((response) => {
        setUser(response.data);
        console.log(response.data);
        // Handle the response, such as showing a success message
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          navigate("/settings");
        }
      });
  }, []);

  if (!localStorage.getItem("token")) {
    return <Navigate to="/settings" />;
  }

  const navigateToProgress = (lang) => {
    navigate("/progress/" + lang);
  };

  return (
    <div className="profile-page">
      {user ? (
        <div className="profile-card">
          <div className="profile-ds-top"></div>
          <div className="profile-avatar-holder">
            <img className="profile-avatar" src="img/avatars/avatar.jpeg" />
          </div>
          <div className="profile-name">
            <h5>{user.username}</h5>
            <h6>
              <span>{user.mobile_no}</span>
            </h6>
            <p>{user.velayat}</p>
            <button
              className="profile-update"
              onClick={() => {
                navigate("/profile/update");
              }}
            >
              Profili üýtgetmek
            </button>
            <button
              className="profile-logout"
              onClick={() => {
                navigate("/logout");
              }}
            >
              Çykmak
            </button>
            <div style={{ marginTop: "10px" }}>
              <p
                className="profile-progress-link"
                onClick={() => {
                  navigateToProgress("english");
                }}
              >
                Inlis dili - {user.all_words.english} soz/
                {user.progress.english} -{" "}
                {(100 / user.all_words.english) * user.progress.english}%
                <br />
              </p>
              <p
                className="profile-progress-link"
                onClick={() => {
                  navigateToProgress("russian");
                }}
              >
                Rus dili - {user.all_words.russian} soz/{user.progress.russian}{" "}
                - {(100 / user.all_words.russian) * user.progress.russian}%
                <br />
              </p>
              <p
                className="profile-progress-link"
                onClick={() => {
                  navigateToProgress("chinese");
                }}
              >
                Hytay dili - {user.all_words.chinese} soz/
                {user.progress.chinese} -{" "}
                {Math.floor(
                  (100 / user.all_words.chinese) * user.progress.chinese
                )}
                %
              </p>
              <p
                className="profile-progress-link"
                onClick={() => {
                  navigateToProgress("top-users");
                }}
              >
                Aktiw öwrenijiler
              </p>
            </div>
          </div>
        </div>
      ) : (
        <CircleLoader color="#33A2F4" size={90} />
      )}
    </div>
  );
}

export function Logout() {
  const navigate = useNavigate();
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  return <Navigate to="/settings" />;
}

export function UpdateProfile() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState();
  const [form, setForm] = React.useState();

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    axios
      .get("https://peydalan.com.tm/api/get-user", { headers: headers })
      .then((response) => {
        setUser(response.data);
        setForm(response.data);
        // Handle the response, such as showing a success message
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (!localStorage.getItem("token")) {
    return <Navigate to="/settings" />;
  }

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateProfile = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    axios
      .post("https://peydalan.com.tm/api/update-user", form, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response, such as showing a success message
        navigate("/profile");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="profile-page">
      {user ? (
        <div className="profile-card">
          <div className="profile-ds-top"></div>
          <div className="profile-avatar-holder">
            <img className="profile-avatar" src="img/avatars/avatar.jpeg" />
          </div>
          <div className="profile-name">
            <h5>Username:</h5>
            <input
              name="username"
              className="input-update"
              type="text"
              onChange={handleFormChange}
              value={form.username}
            />
            <h5>Welaýaty:</h5>
            <select
              className="input-update"
              name="velayat"
              value={form.velayat}
              onChange={handleFormChange}
            >
              <option value={"balkan"}>Balkan</option>
              <option value={"ahal"}>Ahal</option>
              <option value={"mary"}>Mary</option>
              <option value={"lebap"}>Lebap</option>
              <option value={"dasoguz"}>Daşoguz</option>
              <option value={"ashgabat"}>Aşgabat</option>
            </select>

            <button
              className="profile-update"
              onClick={() => {
                updateProfile();
              }}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        <CircleLoader color="#33A2F4" size={90} />
      )}
    </div>
  );
}
