import React from "react";
import { CircleLoader } from "react-spinners";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function Progress() {
  const navigate = useNavigate();
  const [progress, setProgress] = React.useState();
  const { lang } = useParams();

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    axios
      .get("https://peydalan.com.tm/api/get-progress?lang=" + lang, {
        headers: headers,
      })
      .then((response) => {
        setProgress(response.data);
        // Handle the response, such as showing a success message
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          navigate("/settings");
        }
      });
  }, [lang]);

  if (!localStorage.getItem("token")) {
    return <Navigate to="/settings" />;
  }

  const resetProgress = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    axios
      .post(
        "https://peydalan.com.tm/api/reset-progress",
        { lang: lang },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setProgress(response.data);
        // Handle the response, such as showing a success message
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          navigate("/settings");
        }
      });
  };

  return (
    <div>
      {progress ? (
        <div style={{ padding: 40 }}>
          {progress.map((item, index) => {
            return (
              <div>
                <h6>
                  {item.name} - {item.word_count} sozden - {item.progress}
                </h6>
              </div>
            );
          })}
          <button onClick={resetProgress}>Arassala</button>
        </div>
      ) : (
        <CircleLoader color="#33A2F4" size={90} />
      )}
    </div>
  );
}

export function TopUsers() {
  const navigate = useNavigate();
  const [topUsers, setTopUsers] = React.useState();

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/get-top-users")
      .then((response) => {
        setTopUsers(response.data);
        // Handle the response, such as showing a success message
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {topUsers ? (
        <div style={{ padding: 40 }}>
          {topUsers.map((item, index) => {
            return (
              <div
                style={{
                  marginBottom: 30,
                  border: "1px solid green",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <h6>
                  {item.username} - ( {Capitalize(item.velayat)} )
                </h6>
                <p>
                  English -{item.english_word_count} sözden ={" "}
                  {Math.floor(
                    (100 * item.english_progress) / item.english_word_count
                  )}
                  %
                  <br />
                  Russian -{item.russian_word_count} sözden ={" "}
                  {Math.floor(
                    (100 * item.russian_progress) / item.russian_word_count
                  )}
                  %<br />
                  Chinese - {item.chinese_word_count} sözden ={" "}
                  {Math.floor(
                    (100 * item.chinese_progress) / item.chinese_word_count
                  )}
                  %
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <CircleLoader color="#33A2F4" size={90} />
      )}
    </div>
  );
}
