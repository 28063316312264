import React from "react";
import Card from "./cards/ServiceCard";
import { ServiceAdminCard } from "./admins/ServiceAdmin";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

export function Services() {
  const [services, setServices] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [velayats, setVelayats] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const [favorites, setFavorites] = React.useState([]);
  const [showFavorites, setShowFavorites] = React.useState(false);
  const next = React.useRef(null);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const message = React.useRef();

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/service", { headers: headers })
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setServices(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    // Get Categories
    axios
      .get("https://peydalan.com.tm/api/category?type=service")
      .then((response) => {
        // set the array state to the response data
        setCategories(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    // Get Categories
    axios
      .get("https://peydalan.com.tm/api/velayat?type=service")
      .then((response) => {
        // set the array state to the response data
        setVelayats(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const getNext = () => {
    axios
      .get(next.current)
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setServices((breaks) => [...breaks, ...response.data.results]);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  React.useEffect(() => {
    axios
      .get(
        "https://peydalan.com.tm/api/service?category=" +
          selectedCategory +
          "&region=" +
          selectedRegion,
        { headers: headers }
      )
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setServices(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [selectedCategory, selectedRegion]);

  const selectCategory = (event) => {
    const item = categories.filter(
      (item) => item.id.toString() === event.target.value
    )[0];
    if (item && item.is_empty) {
      message.current =
        "Bu kategoriýada entek haryt goýulmadyk. Habarlaşmak: +99364197532";
      setPopupOpened(true);
    } else {
      setSelectedCategory(event.target.value);
    }
  };

  const selectRegion = (event) => {
    const item = velayats.filter(
      (item) => item.id.toString() === event.target.value
    )[0];
    if (item && item.is_empty) {
      message.current =
        "Bu welaýatda entek haryt ýa-da market goýulmadyk. Habarlaşmak: +99364197532";
      setPopupOpened(true);
    } else {
      setSelectedRegion(event.target.value);
    }
  };

  const addToFavorites = (id) => {
    const updatedData = services.map((item) => {
      if (item.id === id) {
        if (item.liked === true) {
          return { ...item, liked: false };
        } else {
          return { ...item, liked: true };
        }
      } else {
        return item;
      }
    });

    setServices(updatedData);
  };

  React.useEffect(() => {
    setFavorites(
      services.filter((object) => {
        return object.liked === true;
      })
    );
  }, [services]);

  const toggleFavorite = () => {
    setShowFavorites(!showFavorites);
  };

  return (
    <div className="text-frame">
      {" "}
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">{message.current}</div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                OK
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      <div>
        <h2>Hyzmatlar</h2>
      </div>
      <div className="product-top">
        <select
          disabled={disabled}
          value={selectedRegion}
          className="break-select"
          onChange={(event) => {
            selectRegion(event);
          }}
        >
          <option value="">Welaýat Hemmesi</option>
          {velayats.map((item) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select>
        <select
          disabled={disabled}
          className="break-select"
          value={selectedCategory}
          onChange={(event) => {
            selectCategory(event);
          }}
        >
          <option value={""}>Kategoriýa Hemmesi</option>
          {categories.map((item) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select>

        <button
          className="break-select break-button"
          onClick={toggleFavorite}
          disabled={!localStorage.getItem("token")}
        >
          {showFavorites ? "Halanlarymy aýyr" : "Halanlarymy saýla"}
        </button>
      </div>
      {showFavorites
        ? favorites
            .reverse()
            .map((item) => (
              <Card key={item.id} item={item} addToFavorites={addToFavorites} />
            ))
        : services.map((item) => (
            <Card key={item.id} item={item} addToFavorites={addToFavorites} />
          ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}

export function MyServices() {
  const [services, setServices] = React.useState([]);
  const next = React.useRef(null);
  const navigate = useNavigate();

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get(
        "https://peydalan.com.tm/api/service?code=" +
          localStorage.getItem("service"),
        { headers: headers }
      )
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setServices(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const getNext = () => {
    axios
      .get(next.current)
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setServices((breaks) => [...breaks, ...response.data.results]);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const refresh = () => {
    axios
      .get(
        "https://peydalan.com.tm/api/service?code=" +
          localStorage.getItem("service"),
        { headers: headers }
      )
      .then((response) => {
        // set the array state to the response data
        next.current = response.data.next;
        setServices(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const addService = () => {
    navigate("/market/mine/services/add");
  };

  return (
    <div className="text-frame">
      <div>
        <h2>Hyzmatlar</h2>
      </div>
      <div className="product-top">
        <button
          disabled={!localStorage.getItem("service") || services.length > 0}
          className="break-select break-button"
          onClick={addService}
        >
          Hyzmat goş
        </button>
      </div>
      {services.map((item) => (
        <ServiceAdminCard key={item.id} item={item} refresh={refresh} />
      ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}

export function ServiceDetail() {
  const [service, setService] = React.useState();
  const { id } = useParams();

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/service?id=" + id)
      .then((response) => {
        // set the array state to the response data
        setService(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  return (
    <div className="text-frame">
      <h2>Hyzmatlar</h2>
      {service && <Card key={service.id} item={service} />}
    </div>
  );
}
